import { Tabs } from "antd";
import TopicTagBasicInfoView from "../../components/community/topic_tag_basicinfo_view";
import TopicTagTopPostsView from "../../components/community/topic_tag_top_post_view";
import { useLocation } from "react-router-dom";

const TagDetailScreen = () => {
    const location = useLocation();
    const defaultData = location.state && location.state.detail ? JSON.parse(location.state.detail) : null;

    const tabItems = () => {
        if (!defaultData) {
            return [
                {
                    label: 'Basic Info',
                    key: '1',
                    children: <TopicTagBasicInfoView />,
                },
            ];
        }
        return [
            {
                label: 'Basic Info',
                key: '1',
                children: <TopicTagBasicInfoView />,
            },
            {
                label: 'Top Posts',
                key: '2',
                children: <TopicTagTopPostsView />
            },
        ]
    }

    return (
        <div className='main-wrapper'>
            <div className='cnt-item'>
                <Tabs tabBarStyle={{ padding: '10px 10px 0 10px' }} items={tabItems()} />
            </div>
        </div>
    )
}

export default TagDetailScreen;