import { Flex, Progress, Card } from 'antd';

const WalletBarChart = () => {
    return (
        <Card styles={{ body: { padding: 20 } }}>
            <Flex vertical align='center'>
                <div className='static-title'>Wallet Active Users</div>
                <Progress
                    style={{marginTop: 12}}
                    type="dashboard"
                    strokeWidth={6}
                    percent={32}
                    size={160} />
                <div className='static-tips' style={{textAlign: 'center'}}>
                    Currently just a few users want to activate the wallet in app
                </div>
            </Flex>
        </Card>
    )
}

export default WalletBarChart;