import { useEffect, useState, useRef } from 'react'
import { deleteRecommendUser, fetchRecommendUsers } from '../../api/community';
import { Space, Avatar, Tooltip, Button, Popconfirm, Table, Pagination, Flex } from 'antd';
import { PlusOutlined, UserOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { message } from 'antd';
import RecommendEditView from '../../components/community/recommend_edit_view';

const UserRecommendScreen = () => {
    const [tabData, setTabData] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [currUser, setCurrUser] = useState();
    const tooltipRef = useRef(null);

    const tabCols = [
        {
            title: 'Registered',
            dataIndex: 'user',
            key: 'user',
            render: (text, _) => {
                return <label style={{ fontSize: 12, color: '#afafaf' }}>{dayjs(text.createdAt).format('YYYY-MM-DD HH:mm')}</label>
            }
        },
        {
            title: 'User Info',
            dataIndex: 'user',
            key: 'user',
            render: (record, _) => {
                let src = record.picture ?? record.avatar;
                return (
                    <Space size={4}>
                        {
                            src ?
                                <Avatar size={32} src={src} />
                                :
                                <Avatar icon={<UserOutlined />} style={{ backgroundColor: '#87d068' }} />
                        }
                        <div>
                            <Flex align='center'>
                                <b style={{ marginRight: 4 }}>{record.name ? record.name : record.email}</b>
                                {
                                    record.certifications && getVerification(record.certifications[0]?.type, record.certifications[0]?.description)
                                }
                            </Flex>
                            <div style={{ color: '#afafaf', fontSize: 12 }}>{record.name ? record.email : ''}</div>
                        </div>
                    </Space>
                );
            }
        },
        {
            title: 'User Id',
            dataIndex: 'userId',
            key: 'userId',
        },
        {
            title: 'Recommend Weight',
            dataIndex: 'weight',
            key: 'weight',
            render: (text, _) => {
                return <b>{text}</b>
            }
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <Button.Group size='small'>
                        <Button type='link' onClick={() => { editHandle(record) }}>Edit</Button>
                        <Popconfirm
                            title="Delete Action"
                            description="Are you sure to delete this recommend user?"
                            onConfirm={() => { deleteHandle(record) }}
                            okText="Yes"
                            cancelText="No" >
                            <Button danger type='link'>Delete</Button>
                        </Popconfirm>
                    </Button.Group>
                )
            }
        }
    ];

    useEffect(() => {
        setLoading(true);
        getRecommendUsers(pageNum, pageSize);
    }, [])

    const getRecommendUsers = (pNum, pSize) => {
        let params = {
            'page.num': pNum,
            'page.size': pSize,
        }
        fetchRecommendUsers(params).then(res => {
            if (res.list) {
                setTabData(res.list);
            }
            if (res.page) {
                setTotal(res.page.total);
            }
        }).finally(() => setLoading(false))
    }

    const pageChange = (page, size) => {
        setPageNum(page);
        setPageSize(size);
        getRecommendUsers(page, size);
    }

    const addNewRecommend = () => {
        setOpen(true);
    }

    const editHandle = (record) => {
        setCurrUser(record);
        setOpen(true);
    }

    const deleteHandle = (record) => {
        setLoading(true);
        deleteRecommendUser(record.id).then(res => {
            if(res) {
                message.success('deleted!');
                getRecommendUsers(pageNum, pageSize);
            }
        }).catch(err => {
            message.error(String(err));
        }).finally(() => {
            setLoading(false);
        })
    }

    const getVerification = (type, description) => {
        if(type === 'VerifiedUser' || type === 'VerifiedOrganisation') {
            if(description) {
                return (
                    <Tooltip ref={tooltipRef} title={description}>
                        <div>
                            <Avatar size={16} src={type === 'VerifiedUser' ? '/images/personal.png':'/images/company.png'} />
                        </div>
                    </Tooltip>
                )
            } 
            return <Avatar size={16} src={type === 'VerifiedUser' ? '/images/personal.png':'/images/company.png'} />
        }
        return <div />
    }

    return (
        <div className='main-wrapper'>
            <div className='top-part cnt-item'>
                <Button type='primary' icon={<PlusOutlined />} onClick={addNewRecommend}>Add New Recommend User</Button>
            </div>
            <div className='cnt-item'>
                <Table loading={loading} rowKey={(record) => record.id} rowClassName={'table-row'} size='small' dataSource={tabData} columns={tabCols} pagination={false} />
            </div>
            <div className='footer-part cnt-item'>
                <Pagination
                    total={total}
                    showTotal={(total) => `total ${total}`}
                    current={pageNum}
                    pageSize={pageSize}
                    showSizeChanger={true}
                    pageSizeOptions={[10, 20, 30, 50]}
                    onChange={(page, size) => pageChange(page, size)}
                />
            </div>

            <RecommendEditView
                open={open}
                defaultUser={currUser}
                onClose={() => {
                    setOpen(false);
                    setCurrUser(null);
                }}
                onUpdate={() => {
                    setLoading(true);
                    getRecommendUsers(pageNum, pageSize);
                }}
            />
        </div>
    )
}

export default UserRecommendScreen;