import { Drawer, Space, Button, Tag } from "antd"
import { VideoCategoryColors, VideoCategoryOptions } from "../../screens/discover/detail";
import dayjs from "dayjs";
import UserInfoView from "../community/user_info_view";

const DiscoverPreview = ({ open, discover, onClose }) => {
    if (!discover) return <div />
    const { user, resources, videoType, createdAt } = discover;
    
    return (
        <div>
            <Drawer
                forceRender={true}
                title={'Discover Review'}
                width={480}
                maskClosable
                onClose={onClose}
                open={open}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                extra={
                    <Button onClick={onClose}>Close</Button>
                }
            >
                <Space style={{ width: '100%' }}>
                    <UserInfoView user={user} showWallet={false} />
                    <Tag color={VideoCategoryColors[videoType]}>{VideoCategoryOptions.find(item => item.value === videoType).label}</Tag>
                </Space>
                <div style={{ color: '#ccc', fontSize: 12, marginTop: 12 }}>{dayjs(createdAt).format('YYYY-MM-DD HH:mm')}</div>
                <div style={{ margin: '16px 0' }}>
                    <video autoPlay controls src={open ? (resources[0].url ? resources[0].url : resources[0]) : ''} style={{ width: '100%', height: 'auto' }} />
                </div>
                <div className="post-content-review" id='postContainer' />
            </Drawer>
        </div>
    )
}

export default DiscoverPreview;