import { Modal, Form, Upload, Button, message } from "antd";
import { useState } from "react";
import { getBaseApi } from "../../utils/base_url";
import cookie from "../../utils/cookie";
import { UploadOutlined } from "@ant-design/icons";

const VirtualUsersUploadModalView = ({ open, onClose, onUpdate }) => {
    const [loading, setLoading] = useState(false);

    const onUploadChange = ({ file }) => {
        if (file.status === 'uploading') {
            if (!loading) {
                message.loading('file uploading...', 0);
                setLoading(true);
            }
        } else {
            message.destroy();
            setLoading(false);
            if (file.status === 'done') {
                message.success('Upload successfully!');
                onClose();
                onUpdate();
            } if (file.status === 'error') {
                if (file.response?.error) {
                    message.error(file.response?.error, 5);
                } else {
                    message.error('Server error!', 5);
                }
            }
        }
    }

    return (
        <Modal open={open} onCancel={onClose} width={600} title="Upload Virtual Users" footer={null}>
            <div style={{ padding: 24 }}>
                <Form labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} style={{ maxWidth: 500 }}>
                    <Form.Item label="File" required>
                        <Upload
                            method="POST"
                            showUploadList={false}
                            multiple={false}
                            name="virtualUser"
                            action={`https://${getBaseApi()}/api/admin/upload/virtual/user`}
                            headers={{
                                'Authorization': `Bearer ${cookie.getCookie('user-token')}`,
                                'X-Requested-With': null,
                            }}
                            onChange={onUploadChange} >
                            <Button type="primary" loading={loading} icon={<UploadOutlined />}>Upload file here</Button>
                        </Upload>
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    )
}

export default VirtualUsersUploadModalView;