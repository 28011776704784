import { Modal, Descriptions, Input, Select, message } from "antd"
import { useEffect, useState } from "react"
import { createAdmin, fetchRoles, updateAdmin } from "../../api/admin";

const AdminUserEditModalView = ({ defaultAdmin, open, onClose, onUpdate }) => {
    const [data, setData] = useState();
    const [roles, setRoles] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setData(defaultAdmin);
    }, [defaultAdmin]);
    useEffect(() => {
        fetchRoles({ 'page.disable': true }).then(res => {
            if (res.list) {
                setRoles(res.list.map(item => {
                    return {
                        label: item.word,
                        value: item.id,
                        key: item.id,
                    }
                }))
            }
        })
    }, [])

    const onInputChange = (key, value) => {
        let updated = { ...(data ?? {}) };
        updated[key] = value;
        setData(updated);
    }

    const disabledSubmit = () => {
        return !data?.username || !data?.email || !data?.roleId;
    }

    const toSubmit = () => {
        if (disabledSubmit()) return;
        setLoading(true);
        let params = {
            id: data?.id,
            username: data?.username,
            email: data?.email,
            roleId: data?.roleId,
            remark: data?.remark
        }
        if (defaultAdmin) {
            updateAdmin(params).then(res => {
                if (res) {
                    message.success('saved!');
                    onClose();
                    onUpdate();
                }
            }).finally(() => {
                setLoading(false);
            })
        } else {
            createAdmin(params).then(res => {
                if (res) {
                    message.success('saved!');
                    onClose();
                    onUpdate();
                }
            }).finally(() => {
                setLoading(false);
            })
        }
    }

    return (
        <Modal loading={loading} width={600} open={open} maskClosable onCancel={onClose} title={defaultAdmin ? 'Edit Admin' : 'Create Admin'} okText='Confirm' onOk={toSubmit} okButtonProps={{ disabled: disabledSubmit() }}>
            <div style={{ margin: '24px 0' }}>
                <Descriptions bordered colon column={1} labelStyle={{ width: 80, textAlign: 'right' }}>
                    <Descriptions.Item label='Email' span={1}>
                        <Input allowClear placeholder="email" value={data?.email} onChange={evt => onInputChange('email', evt.target.value)} />
                    </Descriptions.Item>
                    <Descriptions.Item label='Name' span={1}>
                        <Input allowClear placeholder="name" value={data?.username} onChange={evt => onInputChange('username', evt.target.value)} />
                    </Descriptions.Item>
                    <Descriptions.Item label='Role' span={1}>
                        <Select allowClear placeholder="select role" style={{ width: '100%' }} options={roles} value={data?.roleId} onChange={value => onInputChange('roleId', value)} />
                    </Descriptions.Item>
                    <Descriptions.Item label='Remark' span={1}>
                        <Input allowClear placeholder="remark" value={data?.remark} onChange={evt => onInputChange('remark', evt.target.value)} />
                    </Descriptions.Item>
                </Descriptions>
            </div>
        </Modal>
    )
}

export default AdminUserEditModalView;