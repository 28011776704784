import { Card, Table } from "antd";
import UserInfoView from "../community/user_info_view";
import { RichText } from "@chatui/core";
import dayjs from "dayjs";

const HotPostsView = () => {
    const hotPosts = [
        {
            id: 1,
            user: {
                name: 'Terence',
                email: 'treza9527@gmail.com',
                avatar: '/images/thumb1.jpg',
            },
            hotValue: '1206',
            content: 'Xi­a­o­mii­a­o­mi Au­to sa­id it wi­ll de­l­i­v­er SU7 ve­h­i­c­l­es on Ma­r­ch 28, the sa­me wi­th the of­f­i­c­i­al la­u­n­ch da­te, and 59 re­t­a­il st­o­r­es ac­r­o­ss 29 ci­t­i­es in Ch­i­na wi­ll st­a­rt re-or­d­e­rs the sa­me day/ Ho­ng Ko­ng-li­s­ed sh­a­r­es of Xi­a­o­m­i­r­o­se ne­a­r­ly 6% at Tu­e­s­d­ay’s mo­r­n­i­ng tr­a­de ri­g­ht af­t­er the an­n­o­u­n­c­e­m­e­nt.',
            commentCount: '124',
            agreeCount: '45',
        },
        {
            id: 2,
            user: {
                name: 'Terence',
                email: 'treza9527@gmail.com',
                avatar: '/images/thumb1.jpg',
            },
            hotValue: '724',
            content: 'Xi­a­o­mii­a­o­mi Au­to sa­id it wi­ll de­l­i­v­er SU7 ve­h­i­c­l­es on Ma­r­ch 28, the sa­me wi­th the of­f­i­c­i­al la­u­n­ch da­te, and 59 re­t­a­il st­o­r­es ac­r­o­ss 29 ci­t­i­es in Ch­i­na wi­ll st­a­rt re-or­d­e­rs the sa­me day/ Ho­ng Ko­ng-li­s­ed sh­a­r­es of Xi­a­o­m­i­r­o­se ne­a­r­ly 6% at Tu­e­s­d­ay’s mo­r­n­i­ng tr­a­de ri­g­ht af­t­er the an­n­o­u­n­c­e­m­e­nt.',
            commentCount: '52',
            agreeCount: '33',
        },
        {
            id: 3,
            user: {
                name: 'Terence',
                email: 'treza9527@gmail.com',
                avatar: '/images/thumb1.jpg',
            },
            hotValue: '519',
            content: 'Xi­a­o­mii­a­o­mi Au­to sa­id it wi­ll de­l­i­v­er SU7 ve­h­i­c­l­es on Ma­r­ch 28, the sa­me wi­th the of­f­i­c­i­al la­u­n­ch da­te, and 59 re­t­a­il st­o­r­es ac­r­o­ss 29 ci­t­i­es in Ch­i­na wi­ll st­a­rt re-or­d­e­rs the sa­me day/ Ho­ng Ko­ng-li­s­ed sh­a­r­es of Xi­a­o­m­i­r­o­se ne­a­r­ly 6% at Tu­e­s­d­ay’s mo­r­n­i­ng tr­a­de ri­g­ht af­t­er the an­n­o­u­n­c­e­m­e­nt.',
            commentCount: '41',
            agreeCount: '28',
        },
        {
            id: 4,
            user: {
                name: 'Terence',
                email: 'treza9527@gmail.com',
                avatar: '/images/thumb1.jpg',
            },
            hotValue: '374',
            content: 'Xi­a­o­mii­a­o­mi Au­to sa­id it wi­ll de­l­i­v­er SU7 ve­h­i­c­l­es on Ma­r­ch 28, the sa­me wi­th the of­f­i­c­i­al la­u­n­ch da­te, and 59 re­t­a­il st­o­r­es ac­r­o­ss 29 ci­t­i­es in Ch­i­na wi­ll st­a­rt re-or­d­e­rs the sa­me day/ Ho­ng Ko­ng-li­s­ed sh­a­r­es of Xi­a­o­m­i­r­o­se ne­a­r­ly 6% at Tu­e­s­d­ay’s mo­r­n­i­ng tr­a­de ri­g­ht af­t­er the an­n­o­u­n­c­e­m­e­nt.',
            commentCount: '36',
            agreeCount: '23',
        },
        {
            id: 5,
            user: {
                name: 'Terence',
                email: 'treza9527@gmail.com',
                avatar: '/images/thumb1.jpg',
            },
            hotValue: '302',
            content: 'Xi­a­o­mii­a­o­mi Au­to sa­id it wi­ll de­l­i­v­er SU7 ve­h­i­c­l­es on Ma­r­ch 28, the sa­me wi­th the of­f­i­c­i­al la­u­n­ch da­te, and 59 re­t­a­il st­o­r­es ac­r­o­ss 29 ci­t­i­es in Ch­i­na wi­ll st­a­rt re-or­d­e­rs the sa­me day/ Ho­ng Ko­ng-li­s­ed sh­a­r­es of Xi­a­o­m­i­r­o­se ne­a­r­ly 6% at Tu­e­s­d­ay’s mo­r­n­i­ng tr­a­de ri­g­ht af­t­er the an­n­o­u­n­c­e­m­e­nt.',
            commentCount: '24',
            agreeCount: '16',
        }
    ];
    const tabCols = [
        {
            title: 'Created',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text, _) => {
                return <label style={{ fontSize: 12, color: '#afafaf', whiteSpace: 'nowrap' }}>{dayjs(text).format('MM-DD HH:mm')}</label>
            },
        },
        {
            title: 'Author',
            dataIndex: 'user',
            key: 'user',
            render: (text, record) => {
                return <UserInfoView user={text} showWallet={false} />
            },
        },
        {
            title: 'Content',
            dataIndex: 'content',
            key: 'content',
            render: (_, record) => {
                return (
                    <div style={{ maxWidth: 150 }}>
                        <div className="community-content">
                            <RichText content={record.title ? record.title : record.content} />
                        </div>
                    </div>
                )
            },
        },
        {
            title: 'Likes',
            dataIndex: 'agreeCount',
            key: 'agreeCount',
            render: (text, _) => {
                if (parseFloat(text) <= 0) return '-';
                return text;
            },
        },
        {
            title: 'Comments',
            dataIndex: 'commentCount',
            key: 'commentCount',
            render: (text, _) => {
                if (parseFloat(text) <= 0) return '-';
                return text;
            },
        },
        {
            title: 'Hot',
            dataIndex: 'hotValue',
            key: 'hotValue',
            render: (text, _) => {
                if (parseFloat(text) <= 0) return '-';
                return <b>{text}</b>;
            },
        },
    ]
    return (
        <Card styles={{ body: { padding: 20 } }}>
            <div className="static-title" style={{ marginBottom: 32, fontWeight: 700 }}>Most Popular Posts</div>
            <Table
                rowKey={(record) => record.id}
                rowClassName={'table-row'}
                size='small'
                dataSource={hotPosts}
                columns={tabCols}
                pagination={false}
            />
        </Card>
    )
}
export default HotPostsView;