import { Tabs } from "antd";
import SystemKeyValueView from "../../components/system/system_key_value_view";
import InviteCodeView from "../../components/system/invitecode_view";
import WhitelistView from "../../components/system/whitelist_view";
import AppFeaturesView from "../../components/system/app_features_view";


const SystemSettingScreen = () => {
    const tabItems = () => {
        return [
            {
                label: 'Invite Code',
                key: '1',
                children: <InviteCodeView />,
            },
            {
                label: 'Whitelist',
                key: '2',
                children: <WhitelistView />
            },
            {
                label: 'System Key&Value',
                key: '3',
                children: <SystemKeyValueView />
            },
            {
                label: 'App Release Versions',
                key: '4',
                children: <AppFeaturesView />
            },
        ]
    }

    return (
        <div className='main-wrapper'>
            <div className='cnt-item'>
                <Tabs tabBarStyle={{padding: '10px 10px 0 10px'}} items={tabItems()} />
            </div>
        </div>
    )
}

export default SystemSettingScreen;