import { DeleteOutlined, YoutubeFilled } from "@ant-design/icons";
import { Button, Progress } from 'antd';
import { Image } from "antd";
import { Player } from 'video-react';

const SquarePanelStyle = {
    position: 'absolute',
    left: 0,
    top: 0,
    width: 100,
    height: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(255, 255, 255, 0.7)'
};

const SquareItemRender = ({ file, onDelete, onActionChange }) => {
    if (!file) return <div />
    
    return (
        <div style={{ width: 100, height: 100, overflow: 'hidden', position: 'relative' }}>
            {
                String(file.type).includes('video') || file.url.includes('mp4') ?
                    <div style={{ width: 100, height: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {
                            file.percent == 100 ?
                                <div className="uploader-video-preview">
                                    <Player>
                                        <source src={file.url} />
                                    </Player>
                                </div>
                                :
                                <YoutubeFilled color="#2db7f5" style={{ fontSize: 60 }} />
                        }

                    </div>
                    :
                    <Image style={{ width: 100, height: 100, objectFit: 'contain' }} src={file.url} />
            }
            {
                file.percent < 100 &&
                <div style={SquarePanelStyle}>
                    <Progress
                        type="circle"
                        trailColor="#e6f4ff"
                        percent={file.percent}
                        strokeWidth={16}
                        size={16}
                    />
                </div>
            }
            {
                file.percent == 100 &&
                <div style={{...SquarePanelStyle}}>
                    <Button size="small" shape="circle" icon={<DeleteOutlined style={{ color: '#afafaf' }}/>} onClick={() => onDelete(file.uid)} />
                </div>
            }
        </div>
    )
}

export default SquareItemRender;