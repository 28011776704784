import { Form, Input, Button, message, Modal, Select, Descriptions, Flex } from "antd";
import { useEffect, useState } from "react";
import { createTagUserRewards, updateTagUserReward } from "../../api/community";
import { CloseCircleOutlined } from "@ant-design/icons";
import UserInfoView from "./user_info_view";


const RewardEditModalView = ({ users, open, onClose, onUpdate, isEdit = false }) => {
    const [loading, setLoading] = useState(false);
    const [rewardForm] = Form.useForm();

    useEffect(() => {
        if (users) {
            rewardForm.setFieldValue('users', users.map(item => ({ ...item, key: item.id, chain: (item.chain ? item.chain : null) })));
        } else {
            rewardForm.setFieldValue('users', null);
        }
    }, [users]);

    useEffect(() => {
        if (!open) {
            rewardForm.setFieldValue('users', null);
        }
    }, [open])

    const onSubmit = () => {
        let editUsers = rewardForm.getFieldValue('users');
        // console.log(editUsers); return;
        if (!editUsers || editUsers.length <= 0) return;
        setLoading(true);
        if (isEdit) {
            let record = editUsers[0];
            let params = {
                id: record.id,
                userId: record.user.userId,
                txId: record.txId,
                tag: record.tag.name,
                symbol: record.symbol,
                amount: record.amount,
                postId: record.postId,
                chain: record.chain,
            }
            updateTagUserReward(params).then(res => {
                if (res) {
                    message.success('updated!');
                    onClose();
                    onUpdate();
                }
            }).catch((err) => {
                message.error(String(err));
            }).finally(() => {
                setLoading(false);
            });
        } else {
            let params = {
                list: editUsers.map(item => {
                    let newdata = { ...item };
                    if (newdata.autoWalletID) {
                        newdata.corporateTx = {
                            autoWalletID: newdata.autoWalletID
                        }
                        delete newdata['autoWalletID'];
                    }
                    delete newdata['user'];
                    return newdata;
                }),
            }
            createTagUserRewards(params).then(res => {
                if (res) {
                    message.success('updated!');
                    onClose();
                    onUpdate();
                }
            }).catch((err) => {
                message.error(String(err));
            }).finally(() => {
                setLoading(false);
            });
        }

    }

    return (
        <Modal
            maskClosable
            open={open}
            footer={[
                <Button key="back" loading={loading} onClick={onClose}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" loading={loading} onClick={onSubmit}>
                    Submit
                </Button>,
            ]}
            width='90vw'
            onCancel={onClose}
            title='Send Rewards To Users'
            styles={{
                body: {
                    padding: '40px 0 20px 0',
                    maxHeight: '60vh',
                    overflowY: 'auto'
                }
            }}
        >
            <Form form={rewardForm} layout="vertical">
                <Form.List name='users'>
                    {(subFields, subOpt) => {
                        return (
                            <>
                                {subFields.map((subField, index) => {
                                    let userItem = (rewardForm.getFieldValue('users') ?? [])[index];
                                    if (!userItem) return <div key={index} />;
                                    return (
                                        <Flex key={index} style={{ marginBottom: 32 }}>
                                            <Descriptions
                                                layout="vertical"
                                                style={{ flex: 1 }}
                                                column={4}
                                                size="small" bordered>
                                                <Descriptions.Item label={`User ${index + 1}`} span={1}>
                                                    <div style={{ maxWidth: 200, minWidth: 160 }}>
                                                        <UserInfoView user={userItem.user} showWallet={true} simple />
                                                    </div>
                                                </Descriptions.Item>
                                                <Descriptions.Item label='Chain' span={1}>
                                                    <Form.Item label='Chain' noStyle name={[subField.name, 'chain']}>
                                                        <Select options={[
                                                            { key: 1, label: 'Bitcoin', value: 'bitcoin' },
                                                            { key: 2, label: 'Polygon', value: 'polygon' },
                                                            { key: 3, label: 'Ethereum', value: 'ethereum' },
                                                        ]} style={{ width: 150, border: 'none' }} variant="borderless" placeholder="select chain" allowClear />
                                                    </Form.Item>
                                                </Descriptions.Item>
                                                <Descriptions.Item label='Amount' span={1}>
                                                    <Form.Item label='Amount' noStyle name={[subField.name, 'amount']}>
                                                        <Input style={{ width: 150 }} variant allowClear placeholder='input amount' />
                                                    </Form.Item>
                                                </Descriptions.Item>
                                                <Descriptions.Item label='Symbol' span={1}>
                                                    <Form.Item label='Symbol' noStyle name={[subField.name, 'symbol']}>
                                                        <Input style={{ width: 150 }} variant placeholder="input symbol" allowClear />
                                                    </Form.Item>
                                                </Descriptions.Item>
                                                {
                                                    !isEdit && (
                                                        <Descriptions.Item label='Wallet（optional）' span={1}>
                                                            <Form.Item label='Wallet Id' noStyle name={[subField.name, 'autoWalletID']}>
                                                                <Select options={[
                                                                    { key: 1, label: 'Auto', value: true },
                                                                    { key: 2, label: 'Mannual', value: false },
                                                                ]} style={{ border: 'none', width: '100%' }} variant="borderless" placeholder="select wallet" allowClear />
                                                            </Form.Item>
                                                        </Descriptions.Item>
                                                    )
                                                }
                                                <Descriptions.Item label='TxId（optional）' span={3}>
                                                    <Form.Item label='TxId' noStyle name={[subField.name, 'txId']}>
                                                        <Input.TextArea style={{ border: 'none' }} variant allowClear placeholder='input txid' autoSize />
                                                    </Form.Item>
                                                </Descriptions.Item>
                                            </Descriptions>
                                            <CloseCircleOutlined
                                                style={{ padding: 20, fontSize: 28, color: '#afafaf' }}
                                                onClick={() => {
                                                    subOpt.remove(subField.name);
                                                }}
                                            />
                                        </Flex>
                                    )
                                })}
                            </>
                        );
                    }}
                </Form.List>
            </Form>
        </Modal>
    )
}

export default RewardEditModalView;