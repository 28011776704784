import request from '../utils/request';

export function createBanner(params) {
    return request.post('/banner/create', params);
}

export function deleteBanner(ids) {
    return request.dataDel(`/banner/delete?id=${ids}`);
}

export function updateBanner(params) {
    return request.post('/banner/update', params);
}

export function fetchBannerList(params) {
    return request.get('/banner/list', params);
}

export function createTopImg(params) {
    return request.post('/top/create', params);
}

export function deleteTopImg(ids) {
    return request.dataDel(`/top/delete?id=${ids}`);
}

export function updateTopImg(params) {
    return request.post('/top/update', params);
}

export function fetchTopImgList(params) {
    return request.get('/top/list', params);
}

export function fetchDailyKnowledgeList(params) {
    return request.get('/knowledge/list', params);
}

export function createDailyKnowledge(params) {
    return request.post('/knowledge/create', params);
}

export function updateDailyKnowledge(params) {
    return request.post('/knowledge/update', params);
}

export function fetchDiscoverList(params) {
    return request.get('/video/list', params);
}

export function createDiscover(params) {
    return request.post('/video/create', params);
}

export function updateDiscover(params) {
    return request.post('/video/update', params);
}
