import * as echarts from 'echarts/core';
import { TooltipComponent, LegendComponent } from 'echarts/components';
import { PieChart } from 'echarts/charts';
import { LabelLayout } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { useEffect } from 'react';

echarts.use([
    TooltipComponent,
    LegendComponent,
    PieChart,
    CanvasRenderer,
    LabelLayout
]);

const UserPieChart = () => {
    let chartDom;
    let barChart;
    useEffect(() => {
        chartDom = document.getElementById('barChart');
        barChart = echarts.init(chartDom);
        let option = {
            title: {
                text: 'User Proportion',
                textStyle: {
                    fontSize: 17,
                    fontWeight: 500,
                    color: '#1a3353'
                }
            },
            tooltip: {
                trigger: 'item'
            },
            legend: {
                top: '0',
                left: 'right',
                orient: 'vertical',
                textStyle: {
                    fontSize: 11
                },
                icon: 'circle',
                itemHeight: 10,
                itemWidth: 10,
            },
            series: [
                {
                    name: 'Users From',
                    type: 'pie',
                    label: {
                        show: false,
                    },
                    radius: ['75%', '100%'],
                    center: ['50%', '100%'],
                    startAngle: 180,
                    endAngle: 360,
                    data: [
                        { value: 1048, name: 'Google' },
                        { value: 735, name: 'Apple' },
                        { value: 580, name: 'Email' },
                    ]
                }
            ]
        };
        barChart.setOption(option);
    }, [])
    return (
        <div id='barChart' style={{ width: '100%', flex: 1 }} />
    )
}

export default UserPieChart;