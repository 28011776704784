import request from '../utils/request';

export function getCourseCategories(params) {
    return request.get('/course/catalog/category', params);
}

export function getCourseCatalogs(params) {
    return request.get('/course/catalog', params);
}

export function getCourseChapters(params) {
    return request.get('/course/chapter', params);
}

export function getCourseItems(params) {
    return request.get('/course', params);
}

export function updateCourseCategories(params) {
    return request.post('/batch/create/course/catalog/category', params);
}

export function deleteCategories(ids) {
    return request.dataDel(`/course/catalog/category?ids=${ids.join(',')}`);
}

export function updateCatalogs(params) {
    return request.post('/batch/create/course/catalog', params);
}

export function deleteCatalogs(ids) {
    return request.dataDel(`/course/catalog?ids=${ids.join(',')}`);
}

export function updateCourseDetail(params) {
    return request.post('/batch/create/course', params);
}

export function updateChapters(params) {
    return request.post('/batch/create/course/chapter', params);
}

export function deleteChapters(ids) {
    return request.dataDel(`/course/chapter?ids=${ids.join(',')}`);
}

export function deleteCourseItems(ids) {
    return request.dataDel(`/course?ids=${ids.join(',')}`)
}

export function getCourseQuestions(params) {
    return request.get('/question', params);
}

export function deleteCourseQuestions(ids) {
    return request.dataDel(`/question?ids=${ids.join(',')}`)
}

export function updateCourseQuestions(params) {
    return request.post('/batch/create/question', params);
}

export function updateCourseQuestionOptions(params){
    return request.post('/batch/create/option', params);
}

export function updateDailyQuestions(params) {
    return request.post('/batch/create/daily/question', params);
}

export function fetchDailyQuestions(params) {
    return request.get('/daily/question', params);
}

export function deleteDailyQuestions(ids) {
    return request.dataDel(`/daily/question?ids=${ids}`);
}

export function deleteCourseQuestionOption(ids) {
    return request.dataDel(`/option?ids=${ids}`);
}

export function deleteDailyQuestionOption(ids) {
    return request.dataDel(`/daily/option?ids=${ids}`);
}