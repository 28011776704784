import React, { useEffect, useState } from 'react';
import { Table, Button, Pagination, message, Space, Tag, Popconfirm } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { fetchDailyQuestions, deleteDailyQuestions } from '../../api/course';
import QuestionEditView from '../../components/course/question_edit_view';

const DailyQaScreen = (props) => {
    const tabCols = [
        {
            title: 'Index',
            dataIndex: 'id',
            key: 'id',
            render: (text, _, index) => ((pageNum - 1) * pageSize + index + 1)
        },
        {
            title: 'Topic',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Options',
            dataIndex: 'options',
            key: 'options',
            render: (text, record) => {
                return (
                    <Space direction="horizontal" wrap={true}>
                        {
                            text.map(item => {
                                return <Tag key={item.id}>
                                    <b>{item.name}.</b>&nbsp;{item.val}
                                </Tag>
                            })
                        }
                    </Space>
                )
            }
        },
        {
            title: 'Right Answer',
            dataIndex: 'answer',
            key: 'answer',
        },
        {
            title: 'Analysis',
            dataIndex: 'analysis',
            key: 'analysis',
            render: (text, _) => {
                return (
                    <div className="question-analysis-container">
                        {text}
                    </div>
                )
            }
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <Button.Group size='small'>
                        <Button type='link' loading={record.isLoading} onClick={() => { editQuestion(record) }}>Edit</Button>
                        <Popconfirm
                            title="Delete Action"
                            description="Are you sure to delete this question?"
                            onConfirm={() => { deleteQuestion(record) }}
                            okText="Yes"
                            cancelText="No" >
                            <Button danger type='link' loading={record.isLoading}>Delete</Button>
                        </Popconfirm>
                    </Button.Group>
                )
            }
        }
    ];
    const [tabData, setTabData] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [curQuestion, setCurQuestion] = useState();

    useEffect(() => {
        fetchData(pageNum, pageSize);
    }, []);

    const fetchData = (pNum, pSize) => {
        setLoading(true);
        let params = { 'page.num': pNum, 'page.size': pSize };
        fetchDailyQuestions(params).then(res => {
            if (res.page) {
                setTotal(res.page.total);
            }
            if (res.list) {
                setTabData(res.list);
            }
        }).finally(() => setLoading(false))
    }

    const pageChange = (page, size) => {
        setPageNum(page);
        setPageSize(size);
        fetchData(page, size);
    }

    const onUpdate = () => {
        fetchData(pageNum, pageSize);
    }

    const addNewQuestion = () => {
        setCurQuestion(null);
        setDrawerOpen(true);
    }

    const editQuestion = (item) => {
        setCurQuestion(item);
        setDrawerOpen(true);
    }

    const deleteQuestion = (item) => {
        setLoading(true);
        deleteDailyQuestions(item.id).then(res => {
            if(res) {
                message.success('deleted!');
            }
        }).catch(err => {
            message.error(String(err));
        }).finally(() => {
            fetchData(pageNum, pageSize);
        });
    }

    return (
        <div className='main-wrapper'>
            <div className='top-part cnt-item'>
                <Button type='primary' icon={<PlusOutlined />} onClick={addNewQuestion}>Add New Daily Question</Button>
            </div>
            <div className='cnt-item'>
                <Table loading={loading} rowKey={(record) => record.id} rowClassName={'table-row'} size='small' dataSource={tabData} columns={tabCols} pagination={false} />
            </div>
            <div className='footer-part cnt-item'>
                <Pagination
                    total={total}
                    showTotal={(total) => `total ${total}`}
                    current={pageNum}
                    pageSize={pageSize}
                    showSizeChanger={true}
                    pageSizeOptions={[10, 20, 30, 50]}
                    onChange={(page, size) => pageChange(page, size)}
                />
            </div>
            <QuestionEditView
                isDaily={true}
                open={drawerOpen}
                defaultQuestion={curQuestion}
                onClose={() => setDrawerOpen(false)}
                onUpdate={onUpdate} />
        </div>
    )
}

export default DailyQaScreen;