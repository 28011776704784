import { Space, Drawer, Button, Col, Form, Row, Input, message } from "antd";
import { useEffect, useState } from "react";
import { createVersionNote, updateVersionNote } from "../../api/system";

const AppFeatureEditView = ({ open, defaultFeature, onClose, onUpdate }) => {
    const [features, setFeatures] = useState({});
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        setFeatures(defaultFeature ?? {});
        if (defaultFeature && Object.keys(defaultFeature).length > 0) {
            form.setFieldValue('version', defaultFeature.version);
            form.setFieldValue('minVersion', defaultFeature.minVersion);
            form.setFieldValue('description', defaultFeature.description);
        } else {
            form.setFieldValue('version', '');
            form.setFieldValue('minVersion', '');
            form.setFieldValue('description', '');
        }
    }, [defaultFeature])

    const onSubmit = () => {
        let params = {
            ...features,
            version: form.getFieldValue('version'),
            minVersion: form.getFieldValue('minVersion'),
            description: form.getFieldValue('description'),
        };
        if (!params.version || !params.description) {
            message.warning('Incomplete information');
            return;
        }
        setLoading(true);
        if (params.id) {
            updateVersionNote(params).then(res => {
                setLoading(false);
                setFeatures({});
                onClose();
                onUpdate();
            }).catch(err => {
                message.error(String(err));
                setLoading(false);
            });
        } else {
            createVersionNote(params).then(res => {
                setLoading(false);
                setFeatures({});
                onClose();
                onUpdate();
            }).catch(err => {
                message.error(String(err));
                setLoading(false);
            });
        }

    }

    return (
        <div>
            <Drawer
                forceRender={true}
                title={defaultFeature ? 'Edit Version Features' : 'Create New Version Features'}
                width={480}
                maskClosable
                onClose={onClose}
                open={open}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                extra={
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button onClick={onSubmit} type="primary" loading={loading}>
                            Submit
                        </Button>
                    </Space>
                }
            >
                <Form
                    layout="vertical"
                    form={form}
                    requiredMark={false} >
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='version' label='Version'>
                                <Input placeholder="version" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='minVersion' label='Min Version'>
                                <Input placeholder="min version" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='description' label='Features'>
                                <Input.TextArea placeholder="new features"  rows={10} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>
        </div>
    )
}

export default AppFeatureEditView;