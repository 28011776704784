import { Modal, Pagination, Table } from 'antd';
import {useEffect, useState} from 'react';
import { fetchVerificaitonApply } from '../../api/community';
import dayjs from 'dayjs';
import UserInfoView from '../community/user_info_view';

const ApplicantModalView = ({open, onClose}) => {
    const tabCols = [
        {
            title: 'Apply Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text, _) => {
                return <label style={{ fontSize: 12, color: '#afafaf', whiteSpace: 'nowrap' }}>{dayjs(text).format('MM-DD HH:mm')}</label>
            },
        },
        {
            title: 'User',
            dataIndex: 'user',
            key: 'user',
            render: (text, record) => {
                return <UserInfoView user={text} showWallet={false} />
            },
        },
    ];
    const [tabData, setTabData] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);

    const pageChange = (page, size) => {
        setPageNum(page);
        setPageSize(size);
        fetchData(page, size);
    }

    useEffect(() => {
        if(open) {
            fetchData(pageNum, pageSize);
        }
    }, [open])

    const fetchData = (pNum, pSize) => {
        setLoading(true);
        let params = {
            'page.num': pNum,
            'page.size': pSize,
        };
        fetchVerificaitonApply(params).then(res => {
            if (res.page) {
                setTotal(res.page.total);
            }
            if (res.list) {
                setTabData(res.list);
            }
        }).finally(() => setLoading(false))
    }

    return (
        <Modal
            maskClosable
            open={open}
            footer={null}
            width={600}
            onCancel={onClose}
            title='Verification Applicants'>
            <div className='main-wrapper'>
                <div className='cnt-item' style={{ marginTop: 10 }}>
                    <Table
                        scroll={{ x: true }}
                        loading={loading}
                        rowKey={(record) => record.id}
                        rowClassName={'table-row'}
                        size='small'
                        dataSource={tabData}
                        columns={tabCols}
                        pagination={false}/>
                </div>
                <div className='footer-part cnt-item'>
                    <Pagination
                        total={total}
                        showTotal={(total) => `total ${total}`}
                        current={pageNum}
                        pageSize={pageSize}
                        showSizeChanger={true}
                        pageSizeOptions={[10, 20, 30, 50]}
                        onChange={(page, size) => pageChange(page, size)}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default ApplicantModalView;