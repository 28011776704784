import request from '../utils/request';

export function fetchNews(params) {
    return request.get('/platform-news/find', params);
}

export function fetchNewsDetail(id) {
    return request.get(`/platform-news/delete?id=${id}`);
}

export function createNews(params) {
    return request.post('/platform-news/create', params);
}

export function updateNews(params) {
    return request.patch('/platform-news/edit', params);
}

export function deleteNews(ids) {
    return request.dataDel(`/platform-news/delete?id=${ids}`);
}