import { Space, Drawer, Button, Col, Form, Row, Input, message, Select } from "antd";
import { useEffect, useMemo, useState } from "react";
import { createPointsProduct, updatePointsProduct } from "../../api/points";
import { customUploaderBtn } from "../comm/custom_uploader_btn";
import { CloudStorageImageUploader } from "../upload";

const ProductEditView = ({ open, defaultProduct, onClose, onUpdate }) => {
    const [product, setProduct] = useState({
        name: null,
        symbol: null,
        amount: null,
        price: null,
        image: null,
        inventory: null,
        period: null,
        periodTimes: null,
        colorBackgroundImage: null,
        weight: null,
        chain: null,
    });
    const [loading, setLoading] = useState(false);
    const [periodDisable, setPeriodDisable] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        setProduct(defaultProduct ?? {});
        if (defaultProduct && Object.keys(defaultProduct).length > 0) {
            form.setFieldValue('name', defaultProduct.name);
            form.setFieldValue('amount', defaultProduct.amount);
            form.setFieldValue('symbol', defaultProduct.symbol);
            form.setFieldValue('price', defaultProduct.price);
            form.setFieldValue('inventory', defaultProduct.inventory);
            form.setFieldValue('period', defaultProduct.period);
            form.setFieldValue('periodTimes', defaultProduct.periodTimes);
            form.setFieldValue('weight', defaultProduct.weight);
            form.setFieldValue('chain', defaultProduct.chain);
            form.setFieldValue('status', defaultProduct.status);
        } else {
            resetFields();
        }
    }, [defaultProduct])

    const resetFields = () => {
        form.setFieldValue('name', null);
        form.setFieldValue('amount', null);
        form.setFieldValue('symbol', null);
        form.setFieldValue('price', null);
        form.setFieldValue('inventory', null);
        form.setFieldValue('period', null);
        form.setFieldValue('periodTimes', null);
        form.setFieldValue('weight', null);
        form.setFieldValue('chain', null);
        form.setFieldValue('status', null);
    }

    const onSubmit = () => {
        let params = {
            ...product,
            name: form.getFieldValue('name'),
            amount: form.getFieldValue('amount'),
            symbol: form.getFieldValue('symbol'),
            price: form.getFieldValue('price'),
            inventory: form.getFieldValue('inventory'),
            period: form.getFieldValue('period'),
            periodTimes: form.getFieldValue('periodTimes'),
            weight: parseInt(form.getFieldValue('weight') ?? '0'),
            chain: form.getFieldValue('chain'),
            status: form.getFieldValue('status'),
        };
        setLoading(true);
        if (params.id) {
            updatePointsProduct(params).then(res => {
                setLoading(false);
                setProduct({});
                resetFields();

                onClose();
                onUpdate();
            }).catch(err => {
                message.error(String(err));
                setLoading(false);
            });
        } else {
            createPointsProduct(params).then(res => {
                setLoading(false);
                setProduct({});
                resetFields();
                
                onClose();
                onUpdate();
            }).catch(err => {
                message.error(String(err));
                setLoading(false);
            });
        }

    }

    const periodChange = (evt) => {
        let value = form.getFieldValue('period');
        if (value === 'unrestricted') {
            setPeriodDisable(true);
            form.setFieldValue('periodTimes', null)
        } else {
            setPeriodDisable(false);
        }
    }

    const onImageUpdate = (flag, data) => {
        if (!data || data.length <= 0) return;
        let newProduct = { ...product };
        newProduct[flag] = data[0].remoteUrl;
        setProduct(newProduct);
    }

    const uploadProductImgProps = {
        name: 'product-img-uploader',
        bucketPath: '/product',
        defaultFileList: useMemo(() => {
            return product && product.image ? [{
                name: (decodeURIComponent(product.image).split('/').pop() ?? '').split('?')[0],
                url: product.image,
                percent: 100,
            }] : []
        }, [product.image]),
        showUploadList: { showRemoveIcon: false },
        isMultiple: false,
        listType: 'picture-card',
        needItemRender: false,
        buttonNode: customUploaderBtn('Upload Img'),
        onUpdate: (data) => onImageUpdate('image', data),
    }

    const uploadProductBgImgProps = {
        name: 'product-bg-img-uploader',
        bucketPath: '/product',
        defaultFileList: useMemo(() => {
            return product && product.colorBackgroundImage ? [{
                name: (decodeURIComponent(product.colorBackgroundImage).split('/').pop() ?? '').split('?')[0],
                url: product.colorBackgroundImage,
                percent: 100,
            }] : []
        }, [product.colorBackgroundImage]),
        showUploadList: { showRemoveIcon: false },
        isMultiple: false,
        listType: 'picture-card',
        needItemRender: false,
        buttonNode: customUploaderBtn('Upload Bg'),
        onUpdate: (data) => onImageUpdate('colorBackgroundImage', data),
    }

    return (
        <div>
            <Drawer
                forceRender={true}
                title={defaultProduct ? 'Edit Product' : 'Create New Product'}
                width={480}
                maskClosable
                onClose={onClose}
                open={open}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                extra={
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button onClick={onSubmit} type="primary" loading={loading}>
                            Submit
                        </Button>
                    </Space>
                }
            >
                <Form
                    layout="vertical"
                    form={form}
                    requiredMark={false} >
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='name' label='Product Name'>
                                <Input placeholder="name" allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <div style={{ fontSize: 14, color: 'rgba(0,0,0,0.88)', paddingBottom: 12 }}>Product Image</div>
                            <CloudStorageImageUploader {...uploadProductImgProps} />
                            <div style={{ height: 16 }} />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <div style={{ fontSize: 14, color: 'rgba(0,0,0,0.88)', paddingBottom: 12 }}>Product Background Image</div>
                            <CloudStorageImageUploader {...uploadProductBgImgProps} />
                            <div style={{ height: 16 }} />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name='status' label='Product status'>
                                <Select options={[
                                    { key: 1, label: 'Waiting', value: 0 },
                                    { key: 2, label: 'On', value: 1 },
                                    { key: 3, label: 'Off', value: 2 },
                                ]}
                                    placeholder="status"
                                    allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name='weight' label='Weight'>
                                <Input placeholder="weight" allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name='amount' label='Amount'>
                                <Input placeholder="amount" allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name='chain' label='Chain'>
                                <Input placeholder="chain" allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name='symbol' label='Symbol'>
                                <Input placeholder="symbol" allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name='inventory' label='Inventory'>
                                <Input placeholder="redeemable inventory" allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name='price' label='Price(point)'>
                                <Input placeholder="price" allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name='period' label='Redemption period'>
                                <Select options={[
                                    { key: 1, label: 'Every year', value: 'year' },
                                    { key: 2, label: 'Every month', value: 'month' },
                                    { key: 3, label: 'Every week', value: 'week' },
                                    { key: 4, label: 'Every day', value: 'day' },
                                    { key: 5, label: 'All', value: 'all' },
                                    { key: 6, label: 'No limit', value: 'unrestricted' },
                                ]}
                                    placeholder="period"
                                    disabled={product.id}
                                    onChange={periodChange}
                                    allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            {
                                periodDisable === false &&
                                <Form.Item name='periodTimes' label='Period Times'>
                                    <Input placeholder="period times" disabled={product.id} allowClear />
                                </Form.Item>
                            }
                        </Col>
                    </Row>
                </Form>
            </Drawer>
        </div>
    )
}

export default ProductEditView;