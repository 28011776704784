import { useEffect, useState } from "react";
import { Pagination, Button, Space, Descriptions, Popconfirm, Table, Typography, Tag } from "antd";
import PostReview from "../../components/community/post_review";
import dayjs from "dayjs";
import { deleteTopicTopPost, fetchTopicTopPosts } from "../../api/community";
import TopicTagPostEditDrawer from "./topic_tag_post_edit_drawer";
import { useLocation } from "react-router-dom";
import UserInfoView from "./user_info_view";

const TopicTagTopPostsView = () => {
    const [posts, setPosts] = useState([]);
    const [open, setOpen] = useState(false);
    const [currPost, setCurrPost] = useState(null);
    const [reviewOpen, setReviewOpen] = useState(false);
    const [searchParams, setSearchParams] = useState({
        title: '',
        status: '',
        start: '',
        end: '',
    });
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const tagInfo = (location.state && location.state.detail ? JSON.parse(location.state.detail) : {});

    const tabCols = [
        {
            title: 'PostId',
            dataIndex: 'id',
            key: 'id',
            render: (_, record) => {
                const {post} = record;
                return <div style={{ width: 80 }}><Typography.Text copyable style={{ fontSize: 11, color: '#afafaf' }}>{post.id}</Typography.Text></div>
            },
        },
        {
            title: 'Author',
            dataIndex: 'user',
            key: 'user',
            render: (_, record) => {
                const {post} = record;
                return <UserInfoView user={post.user} />
            },
        },
        {
            title: 'Title/Content',
            dataIndex: 'title',
            key: 'title',
            render: (_, record) => {
                const {post} = record;
                if(post.title) {
                    return <b>{post.title}</b>
                }
                return (
                    <div className="community-content" style={{width: 240}} dangerouslySetInnerHTML={{__html: post.content}} />
                ) 
            },
        },
        {
            title: 'Created',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (_, record) => {
                const {post} = record;
                return <label style={{ fontSize: 12, color: '#afafaf', whiteSpace: 'nowrap' }}>{dayjs(post.createdAt).format('MM-DD HH:mm')}</label>
            },
        },
        ,
        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
            render: (_, record) => {
                const {post} = record;
                return <Tag color={String(post.category.name).toLowerCase() === 'post' ? 'cyan' : 'blue'} style={{ margin: 0 }}>{post.category.name}</Tag>
            },
        },
        {
            title: 'Weight',
            dataIndex: 'weight',
            key: 'weight',
            render: (text, _) => {
                if (!text) return '-';
                return <b>{text}</b>
            },
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <Button.Group size='small'>
                        <Button type='link' onClick={() => { reviewHandle(record) }}>Review Post</Button>
                        <Popconfirm
                            title="Delete Action"
                            description="Are you sure to remove the top post for this topic?"
                            onConfirm={() => { deleteHandle(record) }}
                            okText="Yes"
                            cancelText="No" >
                            <Button danger type='link'>Cancel Top</Button>
                        </Popconfirm>
                    </Button.Group>
                )
            },
        }
    ];

    useEffect(() => {
        getData(pageNum, pageSize, searchParams);
    }, [])

    const getData = (pNum, pSize, search) => {
        setLoading(true);
        let params = {
            'page.num': pNum,
            'page.size': pSize,
            'tagId': tagInfo.id,
            ...search
        }
        fetchTopicTopPosts(params).then(res => {
            if (res.list) {
                setPosts(res.list);
            }
            if (res.page) {
                setTotal(res.page.total);
            }
            setLoading(false);
        })
    }

    const editHandle = (record) => {
        setCurrPost(record);
        setOpen(true);
    }

    const deleteHandle = (record) => {
        setLoading(true);
        deleteTopicTopPost(record.id).then(res => {
            getData(pageNum, pageSize, searchParams);
        }).finally(() => {
            setLoading(false)
        })
    }

    const reviewHandle = (record) => {
        setCurrPost(record);
        setReviewOpen(true);
    }

    const getExtraView = () => {
        return (
            <Space>
                <Button type="primary" onClick={() => editHandle(null)}>Add Post</Button>
            </Space>
        )
    }

    const pageChange = (page, size) => {
        setPageNum(page);
        setPageSize(size);
        getData(page, size, searchParams);
    }

    return (
        <div style={{ padding: 20 }}>
            <Descriptions title='Top Posts List' extra={getExtraView()} column={3}>
                <Descriptions.Item span={3}>
                    <Table
                        style={{ width: '100%' }}
                        loading={loading}
                        rowKey={(record) => record.post.id}
                        rowClassName={'table-row'}
                        size='small'
                        dataSource={posts}
                        columns={tabCols}
                        pagination={false} />
                </Descriptions.Item>
            </Descriptions>
            <div className='footer-part cnt-item'>
                <Pagination
                    total={total}
                    showTotal={(total) => `total ${total}`}
                    current={pageNum}
                    pageSize={pageSize}
                    showSizeChanger={true}
                    pageSizeOptions={[10, 20, 30, 50]}
                    onChange={(page, size) => pageChange(page, size)}
                />
            </div>
            <TopicTagPostEditDrawer
                tagId={tagInfo?.id}
                open={open}
                defaultPost={currPost}
                onClose={() => setOpen(false)}
                onUpdate={() => getData(pageNum, pageSize, searchParams)} />
            <PostReview
                from="toppost"
                open={reviewOpen}
                post={currPost ? currPost.post : null}
                onClose={() => setReviewOpen(false)}
                canEdit={false} />
        </div>
    );
}
export default TopicTagTopPostsView;