import { Result, Button } from "antd";
import { deleteCookie } from "../../utils/cookie";
import { useNavigate } from "react-router-dom";

const NotAccessScreen = () => {
    const navigateTo = useNavigate();
    const logoutHandle = () => {
        deleteCookie('user-token');
        window.localStorage.removeItem('rjwt');
        navigateTo('/login', {replace: true});
    }

    return (
        <Result
            status="403"
            title="403"
            subTitle="Sorry, you are not authorized to access this page."
            extra={<Button type="primary" onClick={logoutHandle}>Back To Login</Button>}
        />
    )
}

export default NotAccessScreen;