import { PlusOutlined } from "@ant-design/icons";
import { Table, Space, Popconfirm, Button, Input, Pagination, message, Flex } from "antd";
import { useState, useEffect } from "react";
import { fetchUserTags, createUserTag, updateUserTag, deleteUserTag } from "../../api/community";
import UserTagItemEditView from "../../components/community/user_tag_item_edit_view";
import UserTagItemScopeView from "../../components/community/user_tag_item_scope_view";
import { useDebouncedCallback } from "use-debounce";

const UserLabelScreen = () => {
    const [labelItems, setLabelItems] = useState([]);
    const [oldData, setOldData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [keyword, setKeyword] = useState();

    const tabCols = [
        {
            title: 'Index',
            dataIndex: 'id',
            key: 'id',
            render: (text, _, index) => ((pageNum - 1) * pageSize + index + 1),
        },
        {
            title: 'First Level Name',
            dataIndex: 'name',
            key: 'name',
            width: 200,
            render: (text, record, index) => {
                if (record.isEditing) {
                    return <Input
                        style={{ maxWidth: 200, fontSize: 12 }}
                        value={record.name}
                        placeholder="user label"
                        onChange={evt => onInputChange('name', evt.target.value, index)}
                    />;
                }
                return text;
            }
        },
        {
            title: 'Second Level Name',
            dataIndex: 'children',
            key: 'children',
            render: (text, record, index) => {
                return (
                    record.isEditing ?
                        <UserTagItemEditView defaultTags={record.children} onUpdate={(newTags) => onInputChange('children', newTags, index)} />
                        :
                        <UserTagItemScopeView defaultTags={record.children} onUpdate={(newTags) => onScopeChange(newTags, index)} />
                )
            }
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            width: 200,
            render: (text, record, index) => {
                if (record.isEditing) {
                    return <Input
                        style={{ maxWidth: 200, fontSize: 12 }}
                        value={record.description}
                        placeholder="description"
                        onChange={evt => onInputChange('description', evt.target.value, index)}
                    />;
                }
                return text;
            }
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    record.isEditing ?
                        <Button.Group size='small'>
                            <Button type='link' onClick={() => saveEdit(record)}>Save</Button>
                            <Button type='link' onClick={() => cancelEdit()}><label style={{ color: '#afafaf', cursor: 'pointer' }}>Cancel</label></Button>
                        </Button.Group>
                        :
                        <Button.Group size='small'>
                            <Button type='link' onClick={() => editLevel(record)}>Edit</Button>
                            <Popconfirm
                                title="Delete"
                                description='The operation will remove all sub labels, continue?'
                                onConfirm={() => deleteLevel(record)}
                                okText="Yes"
                                cancelText="No" >
                                <Button danger type='link'>Delete</Button>
                            </Popconfirm>
                        </Button.Group>
                )
            }
        }
    ];

    useEffect(() => {
        getData(pageNum, pageSize, keyword);
    }, [])

    const getData = useDebouncedCallback((pNum, pSize, search) => {
        let params = {
            'page.num': pNum,
            'page.size': pSize,
            'name': search,
        }
        setLoading(true);
        fetchUserTags(params).then(res => {
            if (res.list) {
                setLabelItems(JSON.parse(JSON.stringify(res.list)));
                setOldData(JSON.parse(JSON.stringify(res.list)));
            }
            if (res.page) {
                setTotal(res.page.total);
            }
        }).finally(() => setLoading(false))
    }, 300);

    const editLevel = (item) => {
        item.isEditing = true;
        setLabelItems([...labelItems]);
    }

    const deleteLevel = (level) => {
        setLoading(true);
        deleteUserTag(level.id).then(res => {
            getData(pageNum, pageSize, keyword);
        }).catch(err => {
            setLoading(false);
            message.error(err.message);
        })
    }

    const onInputChange = (key, value, index) => {
        labelItems[index][key] = value;
        setLabelItems([...labelItems]);
    }

    const onScopeChange = (value, index) => {
        let newItem = { ...labelItems[index] };
        newItem.children = value;
        setLoading(true);
        updateUserTag(newItem).then(res => {
            getData(pageNum, pageSize, keyword);
        }).catch(err => {
            message.error(err.message);
            setLoading(false)
        })
    }

    const saveEdit = (item) => {
        let canSubmit = String(item.name).trim() && item.children.every(ch => String(ch.name).trim());
        if (!canSubmit) {
            message.warning('Label name can not be empty!');
            return;
        }

        setLoading(true);
        let newItem = { ...item };
        if (newItem.id === '-1') delete newItem['id'];
        let childrens = [...item.children].map(ch => {
            if (ch.id === '-1') {
                let newCh = { ...ch };
                delete newCh['id'];
                return newCh;
            }
            return ch;
        });
        delete newItem['children'];
        if (item.id === '-1') {
            createUserTag({ ...newItem, children: childrens }).then(res => {
                getData(pageNum, pageSize, keyword);
            }).catch(err => {
                message.error(err.message);
                setLoading(false);
            })
        } else {
            updateUserTag({ ...newItem, children: childrens }).then(res => {
                getData(pageNum, pageSize, keyword);
            }).catch(err => {
                message.error(err.message);
                setLoading(false);
            })
        }
    }

    const cancelEdit = () => {
        let old = JSON.parse(JSON.stringify(oldData)).map(item => ({ ...item, isEditing: false }));
        setLabelItems(old);
    }

    const addNewLevel = () => {
        setLabelItems([
            {
                name: '',
                description: '',
                level: '0',
                permissionType: '0',
                children: [],
                id: '-1',
                isEditing: true,
            }, ...labelItems
        ]);
    }

    const pageChange = (page, size) => {
        setPageNum(page);
        setPageSize(size);
        getData(page, size, keyword);
    }

    const onKeywordChange = (value) => {
        setKeyword(value);
        setPageNum(1);
        getData(1, pageSize, value);
    }

    return (
        <div className='main-wrapper'>
            <div className='top-part cnt-item'>
                <Flex justify='space-between'>
                    <Button type='primary' icon={<PlusOutlined />} onClick={addNewLevel}>Add New FirstLevel</Button>
                    <Space>
                        <Input
                            placeholder="keywords search"
                            allowClear
                            onChange={evt => onKeywordChange(evt.target.value)}
                        />
                    </Space>
                </Flex>
            </div>
            <div className='cnt-item'>
                <Table
                    loading={loading}
                    rowKey={(record) => record.id}
                    rowClassName={'table-row'}
                    size='large'
                    dataSource={labelItems}
                    columns={tabCols}
                    pagination={false}
                    expandable={{ rowExpandable: (record) => false, showExpandColumn: false }} />
            </div>
            <div className='footer-part cnt-item'>
                <Pagination
                    total={total}
                    showTotal={(total) => `total ${total}`}
                    current={pageNum}
                    pageSize={pageSize}
                    showSizeChanger={true}
                    pageSizeOptions={[10, 20, 30, 50]}
                    onChange={(page, size) => pageChange(page, size)}
                />
            </div>
        </div>
    )
}
export default UserLabelScreen;