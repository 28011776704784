import request from '../utils/request';

export function fetchDiscovers(params) {
    return request.get('/discover', params);
}

export function createDiscover(params) {
    return request.post('/discover/create', params);
}

export function updateDiscover(params) {
    return request.patch('/discover/edit', params);
}

export function deleteDiscover(id) {
    return request.dataDel(`/post/delete?ids=${id}`);
}

export function fetchHotBountys(params) {
    return request.get('/hot/bounty/event', params);
}

export function createHotBounty(params) {
    return request.post('/hot/bounty/event/create', params);
}

export function updateHotBounty(params) {
    return request.patch('/hot/bounty/event/edit', params);
}

export function deleteHotBounty(id) {
    return request.dataDel(`/hot/bounty/event?id=${id}`);
}

export function fetchDiscoverHotPosts(params) {
    return request.get('/discover/hot/post', params);
}

export function createDiscoverHotPost(params) {
    return request.post('/discover/hot/post/create', params);
}

export function updateDiscoverHotPost(params) {
    return request.patch('/discover/hot/post/edit', params);
}

export function deleteDiscoverHotPost(id) {
    return request.dataDel(`/discover/hot/post?id=${id}`);
}

export function fetchHotVideos(params) {
    return request.get('/discover/hot/video', params);
}

export function createHotVideo(params) {
    return request.post('/discover/hot/video/create', params);
}

export function updateHotVideo(params) {
    return request.patch('/discover/hot/video/edit', params);
}

export function deleteHotVideo(id) {
    return request.dataDel(`/discover/hot/video?id=${id}`);
}