import { Input, Button, message, Descriptions, Drawer, Space, DatePicker } from 'antd';
import { createBountyTag } from '../../api/community';
import { useEffect, useState, useMemo } from 'react';
import { CloudStorageImageUploader } from '../upload';
import { PlusOutlined } from '@ant-design/icons';
import { conversionUtcDate } from '../../utils/comm';
import dayjs from 'dayjs';
import { customUploaderBtn } from '../comm/custom_uploader_btn';

const BountyEditDrawer = ({ open, defaultBounty, onClose, onUpdate }) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});

    useEffect(() => {
        if (defaultBounty) {
            setData(defaultBounty);
        } else {
            setData({
                name: '',
                reward: {
                    endAt: '',
                    count: '',
                    symobl: '',
                    cover: '',
                    description: '',
                    tip: '',
                    rule: '',
                }
            })
        }
    }, [defaultBounty, open]);

    const onSubmit = () => {
        let params = {
            ...data,
        };
        setLoading(true);
        createBountyTag(params).then(res => {
            onClose();
            onUpdate();
        }).catch(err => {
            message.error(String(err));
        }).finally(() => {
            setLoading(false);
        })
    }

    const onTagCoverImageUpdate = (files) => {
        let newData = { ...data };
        let reward = { ...newData.reward };
        newData.reward = { ...reward, cover: files[0] ? (files[0].remoteUrl ? files[0].remoteUrl : files[0].url) : '' }
        setData(newData);
    }


    const tagCoverUploadProps = {
        defaultFileList: useMemo(() => {
            return data.reward && data.reward.cover ? [{
                name: (decodeURIComponent(data.reward.cover).split('/').pop() ?? '').split('?')[0],
                url: data.reward.cover,
                percent: 100,
            }] : [];
        }, [data.reward?.cover]),
        name: 'tag-cover-uploader',
        bucketPath: '/tags',
        listType: 'picture-card',
        isMultiple: false,
        needItemRender: true,
        useCardPreview: true,
        showUploadList: true,
        buttonNode: customUploaderBtn('Upload Cover'),
        onUpdate: onTagCoverImageUpdate
    }

    const onFormChange = (tag, value) => {
        let newData = { ...data };
        if (tag.includes('reward')) {
            let reward = { ...(data.reward ?? {}) }
            if (tag.includes('count')) {
                reward['count'] = value;
            } else if (tag.includes('endAt')) {
                reward['endAt'] = conversionUtcDate(value, 'UTC');
            } else if (tag.includes('desc')) {
                reward['description'] = value;
            } else if (tag.includes('tip')) {
                reward['tip'] = value;
            } else if (tag.includes('rule')) {
                reward['rule'] = value;
            } else if (tag.includes('symbol')) {
                reward['symbol'] = value;
            }
            newData['reward'] = reward;
        } else {
            newData[tag] = value;
        }

        setData(newData);
    }

    if (!data) return <div />;

    return (
        <div>
            <Drawer
                forceRender={true}
                title={defaultBounty ? 'Edit Bounty Topic' : 'Add New Bounty Topic'}
                width={480}
                maskClosable
                onClose={onClose}
                open={open}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                extra={
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button onClick={onSubmit} type="primary" loading={loading}>
                            Submit
                        </Button>
                    </Space>
                }
            >
                <Descriptions column={1} layout="vertical" colon={false} labelStyle={{ marginBottom: 0 }}>
                    <Descriptions.Item span={1} label='Bounty TagName'>
                        <Input
                            style={{ width: '100%' }}
                            variant="filled"
                            value={data.name ?? ''}
                            placeholder="tag name"
                            onChange={evt => onFormChange('name', evt.target.value)}
                            allowClear
                        />
                    </Descriptions.Item>
                    <Descriptions.Item span={1} label='Reward Amount'>
                        <Input
                            style={{ width: '100%' }}
                            variant="filled"
                            value={data.reward ? data.reward.count : ''}
                            type="number"
                            placeholder="reward amounts"
                            onChange={evt => onFormChange('reward_count', evt.target.value)}
                            allowClear
                        />
                    </Descriptions.Item>
                    <Descriptions.Item span={1} label='Reward Symbol'>
                        <Input
                            style={{ width: '100%' }}
                            variant="filled"
                            value={data.reward ? data.reward.symbol : ''}
                            placeholder="reward symbol"
                            onChange={evt => onFormChange('reward_symbol', evt.target.value)}
                            allowClear
                        />
                    </Descriptions.Item>
                    <Descriptions.Item span={1} label='End At'>
                        <DatePicker
                            style={{width: '100%'}}
                            variant="filled"
                            showTime
                            minDate={data.createdAt ? dayjs(data.createdAt).add(1, 'day') : dayjs(Date.now()).add(1, 'day')}
                            value={(data.reward?.endAt) ? dayjs(data.reward.endAt) : ''}
                            format={(value) => `${value ? conversionUtcDate(value) : ''}`}
                            placeholder="End At"
                            onChange={(_, date) => onFormChange('reward_endAt', date.replace('End At: ', ''))}
                        />
                    </Descriptions.Item>
                    <Descriptions.Item label='Cover' span={1}>
                        <CloudStorageImageUploader {...tagCoverUploadProps} />
                    </Descriptions.Item>
                    <Descriptions.Item label='Descrption' span={1}>
                        <Input.TextArea
                            style={{ width: '100%' }}
                            placeholder="reward descrption"
                            value={data.reward ? data.reward.description : ''}
                            rows={2}
                            variant="filled"
                            onChange={evt => onFormChange('reward_desc', evt.target.value)}
                            allowClear
                        />
                    </Descriptions.Item>
                    <Descriptions.Item label='Tips' span={1}>
                        <Input.TextArea
                            style={{ width: '100%' }}
                            placeholder="reward tips"
                            value={data.reward ? data.reward.tip : ''}
                            rows={2}
                            variant="filled"
                            onChange={evt => onFormChange('reward_tip', evt.target.value)}
                            allowClear
                        />
                    </Descriptions.Item>
                    <Descriptions.Item label='Rules' span={1}>
                        <Input.TextArea
                            style={{ width: '100%' }}
                            placeholder="reward rules"
                            value={data.reward ? data.reward.rule : ''}
                            rows={2}
                            variant="filled"
                            onChange={evt => onFormChange('reward_rule', evt.target.value)}
                            allowClear
                        />
                    </Descriptions.Item>
                </Descriptions>
            </Drawer>
        </div>
    )
}

export default BountyEditDrawer;