export function getBaseApi() {
    if (process.env.REACT_APP_MODE === 'test') {
        return process.env.REACT_APP_TEST_BASE_API;
    } else if (process.env.REACT_APP_MODE === 'stage') {
        return process.env.REACT_APP_STAGE_BASE_API;
    } else if (process.env.REACT_APP_MODE === 'prod') {
        return process.env.REACT_APP_PROD_BASE_API;
    }
    return process.env.REACT_APP_DEV_BASE_API;
}

export function getVideoTmpBucket() {
    if (process.env.REACT_APP_MODE === 'test') {
        return process.env.REACT_APP_TEST_VIDEO_BUCKET;
    } else if (process.env.REACT_APP_MODE === 'stage') {
        return process.env.REACT_APP_STAGE_VIDEO_BUCKET;
    } else if (process.env.REACT_APP_MODE === 'prod') {
        return process.env.REACT_APP_PROD_VIDEO_BUCKET;
    }
    return process.env.REACT_APP_DEV_VIDEO_BUCKET;
}

export function getImageBucket() {
    if (process.env.REACT_APP_MODE === 'test') {
        return process.env.REACT_APP_TEST_IMAGE_BUCKET;
    } else if (process.env.REACT_APP_MODE === 'stage') {
        return process.env.REACT_APP_STAGE_IMAGE_BUCKET;
    } else if (process.env.REACT_APP_MODE === 'prod') {
        return process.env.REACT_APP_PROD_IMAGE_BUCKET;
    }
    return process.env.REACT_APP_DEV_IMAGE_BUCKET;
}

export function getVideoOutputBucket() {
    if (process.env.REACT_APP_MODE === 'test') {
        return process.env.REACT_APP_TEST_VIDEO_OUTPUT_BUCKET;
    } else if (process.env.REACT_APP_MODE === 'stage') {
        return process.env.REACT_APP_STAGE_VIDEO_OUTPUT_BUCKET;
    } else if (process.env.REACT_APP_MODE === 'prod') {
        return process.env.REACT_APP_PROD_VIDEO_OUTPUT_BUCKET;
    }
    return process.env.REACT_APP_DEV_VIDEO_OUTPUT_BUCKET;
}

export function getAppCheckV3Key () {
    if (process.env.REACT_APP_MODE === 'test') {
        return '6LdPnfYpAAAAAEWQKAeps_g1Qznqq3qfxxKBWYNN';
    } else if (process.env.REACT_APP_MODE === 'prod') {
        return '6LcPo-UpAAAAAHhnR4Cn_IeiKW4odXwhyQ_UPlTV';
    }
    return ''
}

export function getTxidScanBaseUrl () {
    if (process.env.REACT_APP_MODE === 'prod') {
        return 'https://polygonscan.com/tx';
    }
    return 'https://amoy.polygonscan.com/tx';
}

export function getRootUrl() {
    if (process.env.REACT_APP_MODE === 'test') {
        return 'test.addxgo.io';
    } else if (process.env.REACT_APP_MODE === 'prod') {
        return 'addxgo.io';
    }
    return 'dev.addxgo.io'
}