import Cookies from 'js-cookie';

export function setCookie(keyname, keyval, expires = null) {
    if (expires) {
        let exdate = new Date(expires);
        let currdate = new Date();
        let expdays = (exdate.getTime() - currdate.getTime()) / (1000 * 60 * 60 * 24);
        try {
            Cookies.set(keyname, keyval, { expires: expdays });
        } catch (error) { }
    }
    else {
        Cookies.set(keyname, keyval);
    }
}

export function getCookie(keyname) {
    return Cookies.get(keyname);
}

export function deleteCookie(keyname) {
    Cookies.remove(keyname);
}


export default { setCookie, getCookie, deleteCookie };