import { Col, Row, Card, Flex } from "antd";
import '../../style/dashboard.css';
import UserPlotsView from "../../components/dashboard/user_plots";
import StatisticView from "../../components/dashboard/statistic_view";
import WalletBarChart from "../../components/dashboard/wallet_bar_chart";
import UserPieChart from "../../components/dashboard/user_pie_chart";
import HotUserView from "../../components/dashboard/hot_user_view";
import HotTagsView from "../../components/dashboard/hot_tags_view";
import HotPostsView from "../../components/dashboard/hot_posts_view";

const DashboardScreen = () => {
    return (
        <div style={{ width: '100%' }}>
            <Row align={'stretch'}>
                <Col span={18}>
                    <StatisticView />
                    <Row style={{ marginTop: 16 }}>
                        <Col span={24}>
                            <Card styles={{ body: { padding: 20 } }}>
                                <UserPlotsView />
                            </Card>
                        </Col>
                    </Row>
                </Col>
                <Col span={6}>
                    <div style={{ marginLeft: 16, height: '100%' }}>
                        <Flex vertical style={{ height: '100%' }}>
                            <WalletBarChart />
                            <Card style={{ flex: 1, marginTop: 16 }} styles={{ body: { padding: 12, display: 'flex', flexDirection: 'column', height: '100%' } }}>
                                <UserPieChart />
                            </Card>
                        </Flex>
                    </div>
                </Col>
            </Row>
            <Flex style={{ marginTop: 16 }} align={'stretch'}>
                <div style={{ flex: 5 }}>
                    <HotUserView />
                </div>
                <div style={{ width: 16 }} />
                <div style={{ flex: 5 }}>
                    <HotTagsView />
                </div>
                <div style={{ width: 16 }} />
                <div style={{ flex: 14 }}>
                    <HotPostsView />
                </div>
            </Flex>
        </div>
    )
}

export default DashboardScreen;