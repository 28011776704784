import { CheckOutlined } from "@ant-design/icons";
import { Space, Select, Button } from "antd"
import { useEffect, useState } from "react";

const UserTagPopoverView = ({ defaultTags, confirmCallback, closed, options }) => {
    const [tags, setTags] = useState([]);
    const [needSave, setNeedSave] = useState(false);

    useEffect(() => {
        setTags(defaultTags ? JSON.parse(JSON.stringify(defaultTags)) : []);
    }, [defaultTags])

    useEffect(() => {
        if (tags.sort().join(',') !== defaultTags.sort().join(',')) {
            setNeedSave(true);
        } else {
            setNeedSave(false);
        }
    }, [tags])

    useEffect(() => {
        if (!closed) {
            setTags([]);
        }
    }, [closed])



    const onSelectChange = (val) => {
        setTags(val);
    }

    const toSubmit = () => {
        confirmCallback(tags);
    }

    return (
        <Space size={4}>
            <Select
                mode='multiple'
                style={{ width: 400 }}
                size='large'
                placeholder='select tags'
                value={tags}
                options={options}
                onChange={onSelectChange}
                allowClear
            />
            {
                needSave ?
                    <Button type='primary' shape='circle' icon={<CheckOutlined />} size='large' onClick={toSubmit} />
                    :
                    null
            }
        </Space>
    )
}

export default UserTagPopoverView;