import { useEffect, useState } from "react";
import { Pagination, Flex, Button, Space, Popconfirm, message, Table, Tag, Input, Select, DatePicker } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useDebouncedCallback } from "use-debounce";
import dayjs from "dayjs";
import { Typography } from "antd";
import { deleteHotBounty, fetchHotBountys } from "../../api/discover";
import HotBountyEditView from "../../components/discover/hot_bounty_edit_view";
import { conversionUtcDate } from "../../utils/comm";

const HotBountyScreen = () => {
    const [tabData, setTabData] = useState([]);
    const [open, setOpen] = useState(false);
    const [currTopic, setCurrTopic] = useState(null);
    const [searchParams, setSearchParams] = useState({
        name: '',
        status: '',
        start: '',
        end: '',
    });
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const tabCols = [
        {
            title: 'Index',
            dataIndex: 'id',
            key: 'id',
            render: (text, _, index) => ((pageNum - 1) * pageSize + index + 1),
        },
        {
            title: 'Bounty ID',
            dataIndex: 'tag',
            key: 'tag',
            render: (text, record) => {
                return (
                    <Typography.Text style={{color: '#999', fontSize: 12}} copyable>{record.tag.id}</Typography.Text>
                );
            },
        },
        {
            title: 'Bounty Title',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => {
                return (
                    <Typography.Text mark>{`#${record.tag.name}`}</Typography.Text>
                );
            },
        },
        {
            title: 'Weight',
            dataIndex: 'weight',
            key: 'weight',
            render: (text, _) => {
                if (String(text) === '0') return '-';
                return (
                    <b>{text}</b>
                );
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, _) => {
                if (String(text).toLowerCase() === 'active') {
                    return <Tag color="green">{text}</Tag>
                }
                return <Tag color="red">{text}</Tag>
            },
        },
        {
            title: 'CreatedAt',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text, _) => {
                return conversionUtcDate(text, null, true);
            },
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <Button.Group size='small'>
                        <Button type='link' onClick={() => editHandle(record)}>Edit</Button>
                        <Popconfirm
                            title="Delete"
                            description='Are you sure to delete this hot bounty?'
                            onConfirm={() => deleteHandle(record)}
                            okText="Yes"
                            cancelText="No" >
                            <Button danger type='link'>Delete</Button>
                        </Popconfirm>
                    </Button.Group>
                )
            }
        }
    ];

    useEffect(() => {
        getData(pageNum, pageSize, searchParams);
    }, [])

    const getData = (pNum, pSize, search) => {
        setLoading(true);
        let params = {
            'page.num': pNum,
            'page.size': pSize,
            ...search
        }
        fetchHotBountys(params).then(res => {
            if (res.list) {
                setTabData(res.list);
            }
            if (res.page) {
                setTotal(res.page.total);
            }
            setLoading(false);
        })
    }

    const editHandle = (record) => {
        setCurrTopic(record);
        setOpen(true);
    }

    const deleteHandle = (record) => {
        setLoading(true);
        deleteHotBounty(record.id).then(res => {
            setLoading(false);
            getData(pageNum, pageSize, searchParams);
        }).catch(err => {
            message.error(err);
            setLoading(false);
        });
    }

    const addNewHandle = () => {
        setCurrTopic(null);
        setOpen(true);
    }

    const pageChange = (page, size) => {
        setPageNum(page);
        setPageSize(size);
        getData(page, size, searchParams);
    }

    const onSearchChange = useDebouncedCallback(
        (tag, value) => {
            let newParams = { ...searchParams };
            if (tag === 'dates') {
                if (value.length == 2) {
                    newParams.start = `${value[0]}T00:00:00Z`;
                    newParams.end = `${value[1]}T23:59:59Z`;
                } else {
                    newParams.start = '';
                    newParams.end = '';
                }
            } else {
                newParams[tag] = value;
            }
            setSearchParams(newParams);
            setPageNum(1);
            getData(1, pageSize, newParams);
        },
        300
    )

    return (
        <div className='main-wrapper'>
            <div className='top-part cnt-item'>
                <Flex justify='space-between'>
                    <Button type='primary' icon={<PlusOutlined />} onClick={addNewHandle}>Add Hot Bounty</Button>
                    <Space>
                        <Input
                            placeholder="keywords search"
                            allowClear
                            onChange={evt => onSearchChange('name', evt.target.value)}
                        />
                        <Select
                            style={{ width: 160 }}
                            options={[
                                { key: 1, label: 'Active', value: 0 },
                                { key: 2, label: 'Inactive', value: 1 },
                            ]}
                            placeholder='status'
                            onChange={value => onSearchChange('status', value)}
                            allowClear
                        />
                        <DatePicker.RangePicker onChange={values => onSearchChange('dates', (values ?? []).map(date => dayjs(date).format('YYYY-MM-DD')))} />
                    </Space>
                </Flex>
            </div>
            <div className='cnt-item'>
                <Table
                    loading={loading}
                    rowKey={(record) => record.id}
                    rowClassName={'table-row'}
                    size='large'
                    dataSource={tabData}
                    columns={tabCols}
                    pagination={false}
                    expandable={{ rowExpandable: (record) => false, showExpandColumn: false }} />
            </div>
            <div className='footer-part cnt-item'>
                <Pagination
                    total={total}
                    showTotal={(total) => `total ${total}`}
                    current={pageNum}
                    pageSize={pageSize}
                    showSizeChanger={true}
                    pageSizeOptions={[10, 20, 30, 50]}
                    onChange={(page, size) => pageChange(page, size)}
                />
            </div>

            <HotBountyEditView
                open={open}
                defaultTag={currTopic}
                onClose={() => setOpen(false)}
                onUpdate={() => getData(pageNum, pageSize, searchParams)} />
        </div>
    );
}


export default HotBountyScreen;