import { AudioOutlined, DeleteOutlined, YoutubeFilled } from "@ant-design/icons";
import { Input, Button, Progress } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import { Image } from "antd";
import { Player } from 'video-react';

const BannerItemRender = ({ file, onDelete, onActionChange }) => {
    if (!file) return <div />

    const onUpdate = (evt) => {
        if (onActionChange) {
            onActionChange(file, evt.target.value)
        }
    }

    const toPreview = () => {
        if(file.percent == 100) {
            window.open(file.url, '_blank');
        }
    }
    return (
        <div className="banner-img-item" style={{ marginLeft: 0 }}>
            <div style={{ width: 160, display: 'flex', justifyContent: 'center', background: '' }}>
                {
                    String(file.type).includes('video') || file.url.includes('mp4') ?
                        <div style={{ width: 160, height: 90, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {
                                file.percent == 100 ?
                                    <div className="uploader-video-preview" onClick={toPreview}>
                                        <Player src={file.url}>
                                        </Player>
                                    </div>
                                    :
                                    <YoutubeFilled color="#2db7f5" style={{ fontSize: 60 }} />
                            }

                        </div>
                        :
                        String(file.type).includes('audio') || file.url.includes('mp3') ?
                            <div style={{ width: 160, height: 90, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <AudioOutlined onClick={toPreview} color="#2db7f5" style={{ fontSize: 40 }} />
                            </div>
                            :
                            <div className="img">
                                <Image src={file.url} />
                            </div>
                }
            </div>
            <div className="action">
                <div className="lab" style={{ display: 'flex', alignItems: 'center', marginBottom: 2, width: 'auto' }}>
                    <div style={{ width: 20 }}>
                        {
                            file.percent == 100 ?
                                <CheckCircleFilled style={{ color: '#269f50', fontSize: 16 }} />
                                :
                                <Progress
                                    type="circle"
                                    trailColor="#e6f4ff"
                                    percent={file.percent}
                                    strokeWidth={16}
                                    size={16}
                                />
                        }
                    </div>
                    {file.name}
                </div>
                <Input value={file.action} onChange={onUpdate} style={{ padding: '2px 12px' }} placeholder="name / target link / other action" />
            </div>
            <Button shape="circle" icon={<DeleteOutlined style={{ color: '#afafaf' }} onClick={() => onDelete(file.uid)} />} />
        </div>
    )
}

export default BannerItemRender;