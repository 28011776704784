import { Space, Drawer, Button, Col, Form, Row, Input, message } from "antd";
import { useEffect, useMemo, useState } from "react";
import { customUploaderBtn } from "../comm/custom_uploader_btn";
import { CloudStorageImageUploader } from "../upload";
import { createVirtualUser, updateVirtualUser } from "../../api/community";

const VirtualUserEditModalView = ({ open, defaultUser, onClose, onUpdate }) => {
    const [virtualUser, setVirtualUser] = useState({
        name: undefined,
        username: undefined,
        picture: undefined
    });
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        setVirtualUser(defaultUser ?? {});
        if (defaultUser && Object.keys(defaultUser).length > 0) {
            form.setFieldValue('name', defaultUser.name);
            form.setFieldValue('username', defaultUser.username);
        } else {
            resetFields();
        }
    }, [defaultUser])

    const resetFields = () => {
        form.setFieldValue('name', null);
        form.setFieldValue('username', null);
    }

    const onSubmit = () => {
        let params = {
            ...virtualUser,
            name: form.getFieldValue('name'),
            username: form.getFieldValue('username'),
        };
        setLoading(true);
        if (params.userId) {
            updateVirtualUser(params).then(res => {
                if (res) {
                    setVirtualUser({});
                    resetFields();

                    onClose();
                    onUpdate();
                }
            }).finally(() => {
                setLoading(false);
            });
        } else {
            createVirtualUser(params).then(res => {
                if (res) {
                    setVirtualUser({});
                    resetFields();

                    onClose();
                    onUpdate();
                }
            }).finally(() => {
                setLoading(false);
            });
        }

    }

    const onImageUpdate = (flag, data) => {
        if (!data || data.length <= 0) return;
        let newVirtualUser = { ...virtualUser };
        newVirtualUser[flag] = data[0].remoteUrl;
        setVirtualUser(newVirtualUser);
    }

    const uploadVirtualAvatarImgProps = {
        name: 'virtual-avatar-img-uploader',
        bucketPath: '/virtualavatar',
        defaultFileList: useMemo(() => {
            return virtualUser && virtualUser.picture ? [{
                name: (decodeURIComponent(virtualUser.picture).split('/').pop() ?? '').split('?')[0],
                url: virtualUser.picture,
                percent: 100,
            }] : []
        }, [virtualUser.picture]),
        showUploadList: { showRemoveIcon: false },
        isMultiple: false,
        listType: 'picture-card',
        needItemRender: false,
        buttonNode: customUploaderBtn('Upload Img'),
        onUpdate: (data) => onImageUpdate('picture', data),
    }

    return (
        <div>
            <Drawer
                forceRender={true}
                title={defaultUser ? 'Edit Virtual User' : 'Create Virtual User'}
                width={480}
                maskClosable
                onClose={onClose}
                open={open}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                extra={
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button onClick={onSubmit} type="primary" loading={loading}>
                            Submit
                        </Button>
                    </Space>
                }
            >
                <Form
                    layout="vertical"
                    form={form}
                    requiredMark={false} >
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='name' label='Name'>
                                <Input placeholder="name" allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='username' label='Username'>
                                <Input placeholder="username" allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <div style={{ fontSize: 14, color: 'rgba(0,0,0,0.88)', paddingBottom: 12 }}>Virtual User Avatar</div>
                            <CloudStorageImageUploader {...uploadVirtualAvatarImgProps} />
                            <div style={{ height: 16 }} />
                        </Col>
                    </Row>
                </Form>
            </Drawer>
        </div>
    )
}

export default VirtualUserEditModalView;