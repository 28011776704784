import cookie from '../utils/cookie';
import { message } from 'antd';
import axios from 'axios';
import { getBaseApi } from './base_url';
import { refreshJWTToken } from '../firebase/auth';

const BASE_URL = `https://${getBaseApi()}/api/admin`;

function post(url, params, appCheckToken = null) {
    return new Promise((resolve, reject) => {
        let headers = {
            'Content-Type': 'application/json',
        }
        if (url.includes('googleapis')) {
            headers['Content-Type'] = 'application/json';
        } else {
            let token = cookie.getCookie('user-token');
            if (token) {
                headers['Authorization'] = 'Bearer ' + token;
            }
        }

        if (appCheckToken) {
            headers['X-Firebase-Appcheck'] = appCheckToken;
        }

        let fullUrl = url.includes('googleapis') ? url : (BASE_URL + url);
        axios.post(fullUrl, params, { headers, timeout: 60000 }).then(response => {
            resolve(response.data);
        }).catch((err) => {
            if (err.response.status === 401) {
                reAuthentication(async () => {
                    try {
                        let jwt = cookie.getCookie('user-token');
                        headers['Authorization'] = 'Bearer ' + jwt;
                        let result = await axios.post(fullUrl, params, { headers, timeout: 60000 })
                        resolve(result.data);
                    } catch (error) {
                        if (error.response.data && error.response.data.message) {
                            message.error(error.response.data.message, 8)
                        }
                        resolve(false);
                    }
                });
            } else if (url.includes('googleapis')) {
                resolve(false);
            } else {
                if (err.response.data && err.response.data.message) {
                    message.error(err.response.data.message, 8)
                }
                resolve(false);
            }
        });
    })
}

function get(url, params) {
    return new Promise((resolve, reject) => {
        let headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
        }
        let token = cookie.getCookie('user-token');
        if (token) {
            headers['Authorization'] = 'Bearer ' + token;
        }
        axios.get(BASE_URL + url, { params, headers }, { timeout: 60000 }).then(response => {
            resolve(response.data);
        }).catch((err) => {
            if (err.response.status === 401) {
                reAuthentication(async () => {
                    try {
                        let jwt = cookie.getCookie('user-token');
                        headers['Authorization'] = 'Bearer ' + jwt;
                        let result = await axios.get(BASE_URL + url, { params, headers }, { timeout: 60000 })
                        resolve(result.data);
                    } catch (error) {
                        if (error.response.data && error.response.data.message) {
                            message.error(error.response.data.message, 8)
                        }
                        resolve(false);
                    }
                });
            } else {
                reject(err);
            }
        });
    })
}

function patch(url, params) {
    return new Promise((resolve, reject) => {
        let headers = {
            'Content-Type': 'application/json',
        }
        let token = cookie.getCookie('user-token');
        if (token) {
            headers['Authorization'] = 'Bearer ' + token;
        }
        axios.patch(BASE_URL + url, params, { headers: headers }, { timeout: 60000 }).then(response => {
            resolve(response.data);
        }).catch((err) => {
            if (err.response.status === 401) {
                reAuthentication(async () => {
                    try {
                        let jwt = cookie.getCookie('user-token');
                        headers['Authorization'] = 'Bearer ' + jwt;
                        let result = await axios.patch(BASE_URL + url, params, { headers: headers }, { timeout: 60000 })
                        resolve(result.data);
                    } catch (error) {
                        if (error.response.data && error.response.data.message) {
                            message.error(error.response.data.message, 8)
                        }
                        resolve(false);
                    }
                });
            } else {
                if (err.response.data && err.response.data.message) {
                    message.error(err.response.data.message, 8)
                }
                resolve(false);
            }
        });
    })
}

function dataDel(url) {
    return new Promise(resolve => {
        let headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
        }
        let token = cookie.getCookie('user-token');
        if (token) {
            headers['Authorization'] = 'Bearer ' + token;
        }
        axios.delete(BASE_URL + url, { params: {}, headers: headers, timeout: 60000 }).then(response => {
            resolve(response.data);
        }).catch((err) => {
            if (err.response.status === 401) {
                reAuthentication(async () => {
                    try {
                        let jwt = cookie.getCookie('user-token');
                        headers['Authorization'] = 'Bearer ' + jwt;
                        let result = await axios.delete(BASE_URL + url, { params: {}, headers: headers, timeout: 60000 })
                        resolve(result.data);
                    } catch (error) {
                        if (error.response.data && error.response.data.message) {
                            message.error(error.response.data.message, 8)
                        }
                        resolve(false);
                    }
                });
            } else {
                if (err.response.data && err.response.data.message) {
                    message.error(err.response.data.message, 8)
                }
                resolve(false);
            }
        })
    })
}

export function reAuthentication(callback) {
    refreshJWTToken(() => {
        callback();
    }, (err) => {
        cookie.deleteCookie('user-token');
        window.localStorage.removeItem('rjwt');
        window.location.reload();
    });
}

export default {
    post,
    get,
    patch,
    dataDel
}
