import { CheckOutlined, PlusOutlined } from "@ant-design/icons";
import { message } from "antd";
import { Input } from "antd";
import { Table, Tag, Popconfirm, Button, Flex, Modal } from "antd";
import { useState, useEffect } from "react";
import { deleteCategories, updateCourseCategories } from "../../api/course";

const CategoriesView = ({ open, defaultCategories, onUpdate, onClose }) => {
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        setCategories(defaultCategories);
    }, [defaultCategories]);

    const tabCols = [
        {
            title: 'First Level Category',
            dataIndex: 'name',
            key: 'name',
            width: 200,
            render: (text, record, index) => {
                if (record.isEditing) {
                    return <Input
                        style={{ maxWidth: 200, fontSize: 12 }}
                        value={record.name}
                        placeholder="please input category"
                        onChange={evt => onInputChange(record.id, null, evt.target.value, index)}
                    />;
                }
                return text;
            }
        },
        {
            title: 'Second Level Category',
            dataIndex: 'children',
            key: 'children',
            render: (text, record) => {
                return (
                    <Flex gap="4px 0" wrap="wrap">
                        {
                            Array.from(text).filter(item => item.id !== '-1').map(item => {
                                return (
                                    record.isEditing ?
                                        <Input
                                            style={{ maxWidth: 150, marginRight: 8, fontSize: 12 }}
                                            value={item.name}
                                            key={item.id}
                                            placeholder="please input category"
                                            onChange={evt => onInputChange(record.id, item.id, evt.target.value)}
                                        />
                                        :
                                        <Tag
                                            key={item.id}
                                            style={{ cursor: 'pointer' }}
                                            color={record.selectedIds.includes(item.id) ? '#1677ff' : null}
                                            onClick={() => selectCategory(record.id, item.id)}>
                                            {item.name}&nbsp;
                                            {record.selectedIds.includes(item.id) ? <CheckOutlined /> : null}
                                        </Tag>
                                )
                            })
                        }
                        {
                            (record.isEditing && record.id !== '-1') ?
                                <>
                                    {
                                        Array.from(text).filter(item => item.id === '-1').map((item, index) => {
                                            return <Input
                                                key={index}
                                                style={{ maxWidth: 150, marginRight: 8, fontSize: 12 }}
                                                value={item.name}
                                                placeholder="new item"
                                                onChange={evt => onInputChange(record.id, '-1', evt.target.value, index)}
                                            />
                                        })
                                    }
                                    <Button
                                        shape="circle"
                                        icon={<PlusOutlined style={{ fontSize: 12 }} />}
                                        onClick={() => addNewSubCategory(record.id)}
                                        size="small" />
                                </> : null
                        }
                    </Flex>
                )
            }
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    record.isEditing ?
                        <Button.Group size='small'>
                            <Button type='link' loading={record.isLoading} onClick={() => saveEdit(record)}>Save</Button>
                            <Button type='link' onClick={() => cancelEdit(record)}><label style={{ color: '#afafaf', cursor: 'pointer' }}>Cancel</label></Button>
                        </Button.Group>
                        :
                        <Button.Group size='small'>
                            <Button type='link' onClick={() => editCategory(record)}>Edit</Button>
                            {
                                record.selectedIds && record.selectedIds.length > 0
                                    ?
                                    <Popconfirm
                                        title="Delete"
                                        description="Are you sure to delete all these items?"
                                        onConfirm={() => deleteCategory(record, record.selectedIds)}
                                        okText="Yes"
                                        cancelText="No" >
                                        <Button danger type='link' loading={record.isLoading}>Delete Items</Button>
                                    </Popconfirm>
                                    :
                                    <Popconfirm
                                        title="Delete"
                                        description="Are you sure to delete this category?"
                                        onConfirm={() => deleteCategory(record, [record.id])}
                                        okText="Yes"
                                        cancelText="No" >
                                        <Button danger type='link' loading={record.isLoading}>Delete</Button>
                                    </Popconfirm>
                            }
                        </Button.Group>
                )
            }
        }
    ];

    const editCategory = (item) => {
        item.isEditing = true;
        setCategories([...categories]);
    }

    const deleteCategory = (category, ids) => {
        category.isLoading = true;
        setCategories([...categories]);
        deleteCategories(ids).then(res => {
            onUpdate();
        });
    }

    const onInputChange = (categoryId, subCategoryId, value, index) => {
        if (subCategoryId === null) {
            categories[index].name = value;
            setCategories([...categories]);
        } else {
            let category = categories.find(item => item.id === categoryId);
            if (!category) return;
            if (subCategoryId === '-1') {
                let newDatas = category.children.filter(item => item.id === '-1');
                if (newDatas[index]) newDatas[index].name = value;
            } else {
                let subCategory = category.children.find(item => item.id === subCategoryId);
                if (subCategory) subCategory.name = value
            }
            setCategories([...categories]);
        }
    }

    const saveEdit = (item) => {
        let canSubmit = String(item.name).trim() && item.children.every(ch => String(ch.name).trim());
        if (!canSubmit) {
            message.warning('Category name can not be empty!');
            return;
        }
        item.isLoading = true;
        setCategories([...categories]);

        let newItem = { ...item };
        if (newItem.id === '-1') delete newItem['id'];
        let childrens = [...item.children].map(ch => {
            if (ch.id === '-1') {
                let newCh = { ...ch };
                delete newCh['id'];
                return newCh;
            }
            return ch;
        });
        delete newItem['children'];
        updateCourseCategories({ list: [newItem, ...childrens] }).then(res => {
            onUpdate();
        })
    }

    const cancelEdit = (item) => {
        item.isEditing = false;
        item.children = [...item.children].filter(ch => ch.id !== '-1');
        let newCategories = [...categories].filter(cgr => cgr.id !== '-1');
        setCategories(newCategories);
    }

    const selectCategory = (categoryId, subCategoryId) => {
        let category = categories.find(item => item.id === categoryId);
        if (!category) return;
        if (category.selectedIds.includes(subCategoryId)) {
            category.selectedIds = category.selectedIds.filter(item => item !== subCategoryId);
        } else {
            category.selectedIds.push(subCategoryId);
        }

        setCategories([...categories]);
    }

    const addNewCategory = () => {
        setCategories([
            {
                name: '',
                parentId: '0',
                children: [],
                id: '-1',
                isEditing: true,
            }, ...categories
        ]);
    }

    const addNewSubCategory = (categoryId) => {
        let category = categories.find(item => item.id === categoryId);
        if (!category) return;
        category.children = [...category.children].concat({ name: '', parentId: categoryId, id: '-1' });
        setCategories([...categories]);
    }

    return (
        <Modal
            title="Course categories management"
            maskClosable
            open={open}
            footer={null}
            width={1000} onCancel={onClose} >
            <div style={{ width: '100%', maxHeight: '80vh', overflow: 'auto' }}>
                <div style={{ margin: '16px 0' }}>
                    <Button type='primary' icon={<PlusOutlined />} onClick={addNewCategory}>New First Category</Button>
                </div>
                <Table rowKey={(record) => record.id} rowClassName={'table-row'} size='small' dataSource={categories} columns={tabCols} pagination={false} expandable={{ rowExpandable: (record) => false, showExpandColumn: false }} />
            </div>
        </Modal>

    )
}

export default CategoriesView;