import { Card, Flex, Space, Avatar } from "antd";

const HotTagsView = () => {
    const hotTags = [
        {
            name: 'BtcPrice',
            users: '199',
            hotValue: '5400',
        },
        {
            name: 'FedxRates',
            users: '156',
            hotValue: '4712',
        },
        {
            name: 'GoldPriceCutDown',
            users: '116',
            hotValue: '3052',
        },
        {
            name: 'NasdaqIndex',
            users: '74',
            hotValue: '2527',
        },
        {
            name: 'FedRaisesInterestRates',
            users: '58',
            hotValue: '946',
        }
    ];
    return (
        <Card style={{ height: '100%' }} styles={{ body: { padding: 20, height: '100%' } }}>
            <div className="static-title" style={{ marginBottom: 12, fontWeight: 700 }}>Topic Top5</div>
            {
                hotTags.map((item, index) => {
                    return (
                        <Flex key={index} style={{ paddingTop: 24 }} align="center">
                            <div className="static-tips" style={{ flex: 1 }}>
                                <div className="hot-user-lab" style={{ color: '#1a3353' }}>#{item.name}</div>
                                <div className="hot-user-lab" style={{ fontSize: 12, fontWeight: 400 }}>{item.users} participated</div>
                            </div>
                            <div style={{ marginLeft: 10 }}><b>{item.hotValue}</b></div>
                        </Flex>
                    )
                })
            }
        </Card>
    )
}

export default HotTagsView;