import { Divider, Flex, Image, Avatar, Space, Button, Spin } from "antd";
import TipIcon from '../../assets/tip.svg';
import VerifiedUserSvg from '../../assets/v1.png';
import VerifiedOrganisationSvg from '../../assets/v2.png';
import LogoSrc from '../../assets/logo2.svg';
import { conversionUtcDate } from "../../utils/comm";
import { LoadingOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";

const VerifiedView = ({ certifications }) => {
    return (
        <>
            {
                certifications.map((item, index) => {
                    if (item.type === 'VerifiedUser') {
                        return <Image key={index} alt="verified user" src={VerifiedUserSvg} style={{ width: 12, height: 12, marginLeft: 4 }} />
                    } else if (item.type === 'VerifiedOrganisation') {
                        return <Image key={index} alt="verified user" src={VerifiedOrganisationSvg} style={{ width: 12, height: 12, marginLeft: 4 }} />
                    }
                    return <span key={index} />
                })
            }
        </>
    )
}

const CommentItemView = ({ data, isLast, replyCallback, viewMoreCallback }) => {
    const [loading, setLoading] = useState(false);
    let tipAmount = data.tip.amount;
    let avatar = data.user.picture ? data.user.picture : data.user.avatar;
    let username = data.user.name ? data.user.name : data.user.username
    if (tipAmount) {
        if (parseInt(tipAmount) == parseFloat(tipAmount)) {
            tipAmount = parseInt(tipAmount).toString()
        }
    }
    const replyHandle = (comment) => {
        replyCallback(comment);
    }

    const viewMoreHandle = () => {
        setLoading(true);
        viewMoreCallback(data.id);
    }

    useEffect(() => {
        setLoading(false);
    }, [data])
    return (
        <div className="comment-item">
            <Flex align="flex-start">
                {
                    avatar ?
                        <Avatar shape="circle" size={32} src={avatar} />
                        :
                        <Image alt="" style={{ width: 32, height: 32 }} src={LogoSrc} />
                }

                <div style={{ flex: 1, marginLeft: 4, borderBottom: (isLast ? 'none' : '1px solid #F6F7F9'), paddingBottom: 16 }}>
                    <div className="user-name">
                        {data.user.admin ? 'ADDX GO' : username}
                        <VerifiedView certifications={data.user.certifications} />
                    </div>
                    <div id={`comment_${data.id}`} className="content" style={{ whiteSpace: 'pre-wrap' }}>
                        <div>
                            {
                                tipAmount &&
                                <div style={{ display: 'inline-block' }} className="comment-tip">
                                    <Flex>
                                        <Image alt="tip" src={TipIcon} style={{ display: 'inline-block', marginRight: 2 }} />
                                        {`${tipAmount} ${data.tip.symbol}`}
                                    </Flex>
                                </div>
                            }
                            <span dangerouslySetInnerHTML={{ __html: data.content }} />
                        </div>
                        {
                            data.resources.length > 0 &&
                            <Image style={{ maxWidth: 120, height: 'auto' }} src={data.resources[0].url} />
                        }
                    </div>
                    <Space>
                        <div className="create-time" style={{ color: '#C8CBCF', marginTop: 4 }}>{conversionUtcDate(data.createdAt)}</div>
                        <Button style={{ padding: 0, fontSize: 12, color: '#999', paddingTop: 3 }} type="link" onClick={() => replyHandle(data)}>Reply</Button>
                    </Space>
                    {
                        data.children.length > 0 && (
                            <div>
                                {
                                    data.children.map((item, index) => {
                                        let chTipAmount = item.tip.amount;
                                        let chAvatar = item.user.picture ? item.user.picture : item.user.avatar;
                                        let chUsername = item.user.name ? item.user.name : item.user.username;
                                        let chReplyUsername = '';
                                        if(item.repliedId !== '0' && item.repliedId !== item.parentId) {
                                            chReplyUsername = item.repliedUser.name ? item.repliedUser.name : item.repliedUser.username;
                                        }
                                        if (chTipAmount) {
                                            if (parseInt(chTipAmount) == parseFloat(chTipAmount)) {
                                                chTipAmount = parseInt(chTipAmount).toString();
                                            }
                                        }
                                        return (
                                            <div key={index} style={{ marginTop: 8 }}>
                                                <Flex align="flex-start">
                                                    {
                                                        chAvatar ? <Avatar shape="circle" size={24} src={chAvatar} /> : <Image alt="" style={{ width: 24, height: 24 }} src={LogoSrc} />
                                                    }
                                                    <div style={{ flex: 1, marginLeft: 4 }}>
                                                        <div className="user-name">
                                                            {item.user.admin ? 'ADDX.GO' : chUsername}
                                                            <VerifiedView certifications={item.user.certifications} />
                                                            {
                                                                chReplyUsername && (
                                                                    <>
                                                                    <span style={{fontSize: 11, margin: '0 4px'}}>▶</span>
                                                                    {chReplyUsername}
                                                                    </>
                                                                )
                                                            }
                                                        </div>
                                                        <div className="content" id={`reply_${item.id}`} style={{ whiteSpace: 'pre-wrap' }}>
                                                            <div>
                                                                {
                                                                    chTipAmount &&
                                                                    <div style={{ display: 'inline-block' }} className="comment-tip">
                                                                        <Flex>
                                                                            <Image alt="tip" src={TipIcon} style={{ display: 'inline-block', marginRight: 2 }} />
                                                                            {`${chTipAmount} ${item.tip.symbol}`}
                                                                        </Flex>
                                                                    </div>
                                                                }
                                                                <span dangerouslySetInnerHTML={{ __html: item.content }} />
                                                            </div>
                                                            {
                                                                item.resources.length > 0 &&
                                                                <Image style={{ maxWidth: 120, height: 'auto' }} src={item.resources[0].url} />
                                                            }
                                                        </div>
                                                        <Space>
                                                            <div className="create-time" style={{ color: '#C8CBCF', marginTop: 4 }}>{conversionUtcDate(item.createdAt)}</div>
                                                            <Button style={{ padding: 0, fontSize: 12, color: '#999', paddingTop: 3}} type="link" onClick={() => replyHandle(item)}>Reply</Button>
                                                        </Space>
                                                    </div>
                                                </Flex>
                                            </div>
                                        )
                                    })
                                }
                                {
                                   (data.childCount > 2 && data.children.length != parseInt(data.childCount)) &&
                                   <Button loading={loading} type="link" style={{padding: 0, fontSize: 12}} onClick={viewMoreHandle}>view more replies</Button> 
                                }
                            </div>
                        )
                    }
                </div>
            </Flex>
        </div>
    );
}

const PostCommentsView = ({ comments, total, loading, replyCallback, viewMoreCallback }) => {
    const replyHandle = () => {
        replyCallback(null);
    }

    const viewMoreHandle = (commentId) => {
        viewMoreCallback(commentId);
    }

    return (
        <div className="comment-wrapper">
            <div style={{ height: 1 }} />
            <Divider style={{ margin: '8px 0 0 0' }} />
            <Flex justify="space-between" align="center">
                <div className="title">Total of {total} comments</div>
                <Button style={{ padding: 0, fontSize: 13, marginRight: 12 }} type="link" onClick={replyHandle}>Add Comment</Button>
            </Flex>
            {
                comments.map((item, index) => {
                    return (
                        <CommentItemView
                            key={index}
                            isLast={index === comments.length - 1 && total <= comments.length}
                            data={item}
                            replyCallback={replyCallback}
                            viewMoreCallback={viewMoreHandle} />
                    )
                })
            }
            {
                loading && 
                <Flex justify="center" align="center" style={{height: 72}}>
                    <Spin indicator={<LoadingOutlined />} size="large" />
                </Flex>
            }
        </div>
    )
}

export default PostCommentsView;