import { Space, Avatar, Tag, Tooltip, Typography } from "antd";
import { MobileTwoTone, UserOutlined } from "@ant-design/icons";
import { useRef } from 'react';

const UserInfoView = ({ user, showWallet = false, simple = false }) => {
    const tooltipRef = useRef(null);
    if (!user) return <div />;
    let src = user.picture ?? user.avatar;
    const { phoneNumber } = user;

    const buildAddressView = () => {
        if (!showWallet) return <div />;
        let address = user.addressList;
        if (!address || address.length <= 0) {
            return <Tag color="warning" style={{ fontSize: 11 }}>No Wallet</Tag>
        }
        return (
            <Space size={4}>

                {
                    address.map((item, index) => {
                        return (
                            <div key={index}>
                                <Tooltip
                                    ref={tooltipRef}
                                    title={
                                        <Typography.Text copyable style={{ maxWidth: 100, fontSize: 12 }} ellipsis>
                                            {item.address}
                                        </Typography.Text>
                                    }
                                    color="white"
                                    trigger={'click'}>
                                    <div>
                                        {item.symbol === 'BTC' && <img alt="wallet" src="/images/btc.svg" style={{ width: 16, height: 'auto', cursor: 'pointer' }} />}
                                        {item.symbol === 'ETH' && <img alt="wallet" src="/images/eth.svg" style={{ width: 16, height: 'auto', cursor: 'pointer' }} />}
                                        {item.symbol === 'MATIC' && <img alt="wallet" src="/images/matic.svg" style={{ width: 16, height: 'auto', cursor: 'pointer' }} />}
                                    </div>
                                </Tooltip>
                            </div>
                        )
                    })
                }
            </Space>
        )
    }
    if (simple) {
        return (
            <Space size={4}>
                <div>
                    <div>
                        <Space align="center" size={4}>
                            <b>{user.username ? user.username : user.name}</b>
                            {phoneNumber && (
                                <Tooltip
                                    ref={tooltipRef}
                                    title={
                                        <Typography.Text copyable style={{ maxWidth: 100, fontSize: 12 }} ellipsis>
                                            {phoneNumber}
                                        </Typography.Text>
                                    }
                                    color="white"
                                    trigger={'click'}>
                                    <MobileTwoTone size={16} />
                                </Tooltip>
                            )}
                        </Space>
                    </div>
                    <div>{buildAddressView()}</div>
                </div>
            </Space>
        )
    }
    return (
        <Space size={4}>
            {
                user.admin ?
                    <Avatar size={showWallet ? 48 : 32} style={{ backgroundColor: 'blue' }}>Admin</Avatar>
                    :
                    (
                        src ?
                            <Avatar size={showWallet ? 48 : 32} src={src} />
                            :
                            <Avatar size={showWallet ? 48 : 32} icon={<UserOutlined />} style={{ backgroundColor: '#87d068' }} />
                    )
            }
            <div>
                <div>
                    <Space align="center" size={4}>
                        <b>{user.name ? user.name : user.email}</b>
                        {/* {user.admin && <div><Tag color="#3b5999">Admin</Tag></div>} */}
                        {phoneNumber && (
                            <Tooltip
                                ref={tooltipRef}
                                title={
                                    <Typography.Text copyable style={{ maxWidth: 100, fontSize: 12 }} ellipsis>
                                        {phoneNumber}
                                    </Typography.Text>
                                }
                                color="white"
                                trigger={'click'}>
                                <MobileTwoTone size={16} />
                            </Tooltip>
                        )}
                    </Space>
                    <div style={{ color: '#afafaf', fontSize: 12 }}>{user.name ? user.email : ''}</div>
                </div>
                <div>{buildAddressView()}</div>
            </div>
        </Space>
    )
}

export default UserInfoView;