import { Input } from 'antd';
import { useState } from 'react';
import { Descriptions } from 'antd';
import '../../style/sto.css';

const AdsForm = ({ defaultData, onUpdate }) => {
    const [data, setData] = useState(defaultData ?? {});

    const onFormChange = (flag, value) => {
        let updated = { ...data };
        updated[flag] = value;
        setData(updated);
        if (onUpdate) onUpdate(updated);
    }

    return (
        <div className='sto-wrapper' style={{ maxHeight: '50vh', overflowY: 'auto' }}>
            <div style={{ height: 20 }} />
            <Descriptions title='Other Info' size='large' bordered column={2} labelStyle={{ width: 200 }}>
                <Descriptions.Item label='Ads Link' span={2}>
                    <Input placeholder="ads link" value={data.link} onChange={(evt) => onFormChange('link', evt.target.value)} allowClear />
                </Descriptions.Item>
            </Descriptions>
        </div>
    )
}

export default AdsForm;