import { Button, Table, Flex, Popconfirm, Space, Tag, message } from "antd";
import { useEffect, useState } from "react";
import { deleteCourseQuestions, getCourseQuestions } from "../../api/course";
import QuestionEditView from "./question_edit_view";

const CourseQuestionView = ({ courseId, onClose }) => {
    const [questions, setQuestions] = useState([]);
    const [curQuestion, setCurQuestion] = useState();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [pageNum, setPageNum] = useState(1);
    const pageSize = 10;
    const tabCols = [
        {
            title: 'Index',
            dataIndex: 'id',
            key: 'id',
            render: (text, _, index) => ((pageNum - 1) * pageSize + index + 1)
        },
        {
            title: 'Topic',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Options',
            dataIndex: 'options',
            key: 'options',
            render: (text, record) => {
                return (
                    <Space direction="horizontal" wrap={true}>
                        {
                            text.map(item => {
                                return <Tag key={item.id}>
                                    <b>{item.name}.</b>&nbsp;{item.val}
                                </Tag>
                            })
                        }
                    </Space>
                )
            }
        },
        {
            title: 'Right Answer',
            dataIndex: 'answer',
            key: 'answer',
        },
        {
            title: 'Analysis',
            dataIndex: 'analysis',
            key: 'analysis',
            render: (text, _) => {
                return (
                    <div className="question-analysis-container">
                        {text}
                    </div>
                )
            }
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <Button.Group size='small'>
                        <Button type='link' loading={record.isLoading} onClick={() => { editQuestion(record) }}>Edit</Button>
                        <Popconfirm
                            title="Delete Action"
                            description="Are you sure to delete this question?"
                            onConfirm={() => { deleteQuestion(record) }}
                            okText="Yes"
                            cancelText="No" >
                            <Button danger type='link' loading={record.isLoading}>Delete</Button>
                        </Popconfirm>
                    </Button.Group>
                )
            }
        }
    ];

    const fetchQuestions = () => {
        getCourseQuestions({ 'page.disable': true, courseId }).then(res => {
            if (res.list) {
                setQuestions(res.list.map(item => ({ ...item, isLoading: false })));
            }
        })
    }

    const addNewQuestion = () => {
        setCurQuestion(null);
        setDrawerOpen(true);
    }

    const editQuestion = (item) => {
        setCurQuestion(item);
        setDrawerOpen(true);
    }

    const deleteQuestion = (item) => {
        item.isLoading = true;
        setQuestions([...questions]);
        deleteCourseQuestions([item.id]).then(res => {
            if(res) {
                message.success('deleted!');
            }
        }).catch(err => {
            message.error(String(err));
        }).finally(() => {
            fetchQuestions();
        });
    }

    const onUpdate = () => {
        fetchQuestions();
    }

    const onTableChange = (pagination) => {
        setPageNum(pagination.current);
    }

    useEffect(() => {
        fetchQuestions();
    }, [])
    return (
        <div>
            <Flex justify="flex-end" style={{ marginBottom: 16 }}>
                <Button type="dashed" onClick={() => addNewQuestion()}>+ Add New Question</Button>
            </Flex>
            <Table
                rowKey={(record) => record.id}
                rowClassName={'table-row'}
                pagination={{ pageSize: pageSize }}
                dataSource={questions}
                columns={tabCols}
                onChange={onTableChange} />
            <QuestionEditView
                open={drawerOpen}
                courseId={courseId}
                defaultQuestion={curQuestion}
                onClose={() => setDrawerOpen(false)}
                onUpdate={onUpdate} />
        </div>
    )
}

export default CourseQuestionView;