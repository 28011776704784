import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from 'firebase/app-check';
import { getStorage } from "firebase/storage";
import { getAppCheckV3Key, getImageBucket, getVideoTmpBucket } from "../utils/base_url";

export const firebaseConfig = (bucketType) => {
    let firebaseConfig = {
        apiKey: "AIzaSyCLH6AYhX32QO_mO8TXQ5tsrIOWq1fYUuM",
        authDomain: "go-wallet-dev-396512.firebaseapp.com",
        projectId: "go-wallet-dev-396512",
        storageBucket: bucketType == BucketType.image ? getImageBucket() : getVideoTmpBucket(),
        messagingSenderId: "254703063534",
        appId: "1:254703063534:web:38fa458945dfa6aaba3eff",
        measurementId: "G-02CF9GXMBL"
    };

    if (process.env.REACT_APP_MODE === 'test') {
        firebaseConfig = {
            apiKey: "AIzaSyAP4Mq1UVjp7UJQU9s8fFBouOz1ZWlTaAM",
            authDomain: "go-wallet-test-396515.firebaseapp.com",
            projectId: "go-wallet-test-396515",
            storageBucket: bucketType == BucketType.image ? getImageBucket() : getVideoTmpBucket(),
            messagingSenderId: "979486348420",
            appId: "1:979486348420:web:a6a56816ca2565d8ba1ed1",
            measurementId: "G-V52SM5381W"
        };
    } else if (process.env.REACT_APP_MODE === 'prod') {
        firebaseConfig = {
            apiKey: "AIzaSyBtURlA2pCuMoi-fHsaGPBjHUhXLAo3nxM",
            authDomain: "go-wallet-production-396515.firebaseapp.com",
            projectId: "go-wallet-production-396515",
            storageBucket: bucketType == BucketType.image ? getImageBucket() : getVideoTmpBucket(),
            messagingSenderId: "562260331334",
            appId: "1:562260331334:web:d179a0dd85b5bcba3c7347",
            measurementId: "G-EGJCV4TJL5"
        };
    }

    // Initialize Firebase
    const app = initializeApp(firebaseConfig, bucketType == BucketType.image ? "img-storage" : "video-storage");

    if (process.env.REACT_APP_MODE === 'test' || process.env.REACT_APP_MODE === 'prod') {
        const appCheck = initializeAppCheck(app, {
            provider: new ReCaptchaEnterpriseProvider(getAppCheckV3Key()),
            isTokenAutoRefreshEnabled: true,
        })
    }
    const storage = getStorage(app);
    return storage;
}

export const BucketType = Object.freeze({
    video: 'VideoBucket',
    image: 'ImageBucket',
});