import request from "../utils/request";

export function fetchPointsBalance(params) {
    return request.get('/point/balance', params);
}

export function fetchPointsHistory(params) {
    return request.get('/point/balance/history', params);
}

export function fetchPointsProduct(params) {
    return request.get('/point/products', params);
}

export function createPointsProduct(params) {
    return request.post('/point/product/create', params);
}

export function updatePointsProduct(params) {
    return request.patch('/point/product/edit', params);
}