import { useEffect, useState } from 'react';
import { getSystemConfigs, updateSystemConfig } from "../../api/system";
import { Table, message, Typography } from 'antd';

const SystemKeyValueView = () => {
    const PAGE_SIZE = 30;
    const tabCols = [
        {
            title: 'Index',
            dataIndex: 'id',
            key: 'id',
            render: (text, _, index) => (index + 1),
        },
        {
            title: 'Key',
            dataIndex: 'key',
            key: 'key',
            render: (text, record) => {
                return <Typography.Text>{text}</Typography.Text>
            },
            width: '30%',
        },
        {
            title: 'value',
            dataIndex: 'value',
            key: 'value',
            render: (text, record) => {
                let val = record.value;
                return <Typography.Text editable={
                    {
                        onChange: (value) => {
                            val = value;
                        },
                        onEnd: () => {
                            editHandle({ ...record, value: val });
                        }
                    }
                }>{text}</Typography.Text>
            },
            width: '50%',
        }
    ];

    const [tabData, setTabData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        setLoading(true);
        getSystemConfigs({'page.disable': true}).then(res => {
            if (res.list) {
                setTabData(res.list);
            }
        }).finally(() => setLoading(false))
    }

    const editHandle = (params) => {
        setLoading(true);
        updateSystemConfig(params).then(res => {
            if(res) {
                message.success('updated!');
                fetchData();
            }
        }).catch(err => {
            message.error(String(err));
        }).finally(() => setLoading(false));
    }

    return (
        <div>
            <Table
                scroll={{ x: true }}
                loading={loading}
                rowKey={(record) => record.key}
                rowClassName={'table-row'}
                size='small'
                dataSource={tabData}
                columns={tabCols}
                pagination={{pageSize: PAGE_SIZE}} />
        </div>
    )
}

export default SystemKeyValueView;