import React, { useEffect, useState } from 'react';
import { Avatar, List, Skeleton, Flex, Drawer, Typography, Spin } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { fetchPostLikes } from '../../api/community';
import UserInfoView from './user_info_view';

const PostLikesView = ({ post, open, onClose }) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [hasMore, setHasMore] = useState(true)
    const pageSize = 20;

    const fetchData = (page) => {
        let params = {
            'postId': post.id,
            'page.num': page,
            'page.size': pageSize
        };
        fetchPostLikes(params).then(res => {
            if (res.list) {
                setData(page > 1 ? [...data, ...res.list] : res.list);
                if (Array.from(res.list).length < pageSize) {
                    setHasMore(false);
                    setPageNum(page > 1 ? page - 1 : 1);
                } else {
                    setHasMore(true);
                }
            }
        }).catch((err) => {
            setHasMore(false);
        }).finally(() => {
            setLoading(false);
        })
    };
    useEffect(() => {
        if (post) {
            setLoading(true);
            setPageNum(1);
            fetchData(1);
        }
    }, [post]);

    const loadMoreData = () => {
        setPageNum(pageNum + 1);
        fetchData(pageNum + 1);
    }

    const getTitle = () => {
        return (
            <Flex justify='space-between' align='center'>
                <div style={{ flex: 1, marginRight: 8 }}>
                    <UserInfoView showWallet={false} user={post.user} />
                </div>
                <div style={{ fontSize: 14, color: '#999', wordBreak: 'break-all' }}>Total {post.agreeCount} Likes</div>
            </Flex>
        )
    }

    if (!post) return <div />
    return (
        <Drawer open={open} width={400} title={getTitle()} onClose={onClose}>
            {
                loading ?
                    <Spin />
                    :
                    <div
                        id="scrollableDiv"
                        style={{
                            height: '100%',
                            overflow: 'auto',
                        }}
                    >
                        <InfiniteScroll
                            dataLength={data.length}
                            next={loadMoreData}
                            hasMore={hasMore}
                            loader={
                                <Skeleton
                                    avatar
                                    paragraph={{
                                        rows: 1,
                                    }}
                                    active
                                />
                            }
                            scrollableTarget="scrollableDiv"
                        >
                            <List
                                dataSource={data}
                                renderItem={(item, index) => (
                                    <List.Item key={item.userId}>
                                        <List.Item.Meta
                                            avatar={<Avatar src={item.picture ? item.picture : item.avatar} />}
                                            title={item.name ? item.name : item.username}
                                            description={item.email}
                                        />
                                        <Typography.Text style={{ fontStyle: 'italic', color: '#afafaf' }}>{index + 1}th</Typography.Text>
                                    </List.Item>
                                )}
                            />
                        </InfiniteScroll>
                    </div>
            }
        </Drawer>
    );
};
export default PostLikesView;