import { Tree } from "antd";
import { menuItems } from '../../router/index'

const RolePermissionPreview = ({ actions = [] }) => {
    const allMenus = [...menuItems];
    let selected = actions.map(item => item.word);
    return (
        <div style={{ maxHeight: '50vh', overflowY: 'auto' }}>
            <Tree treeData={allMenus} checkedKeys={selected} checkable disabled />
        </div>
    )
}

export default RolePermissionPreview;