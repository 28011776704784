import request from "../utils/request";

export function deleteTx(ids) {
    return request.dataDel(`/corporate/tx/delete?ids=${ids}`);
}

export function fetchTxDetail(params) {
    return request.get('/corporate/tx/get', params);
}

export function fetchTxs(params) {
    return request.get('/corporate/tx/list', params)
}

export function updateTx(params) {
    return request.patch('/corporate/tx/update', params)
}

export function createAdminWallet(params) {
    return request.post('/corporate/wallet/create', params)
}

export function deleteAdminWallet(ids) {
    return request.dataDel(`/corporate/wallet/delete?ids=${ids}`)
}

export function fetchWallets(params) {
    return request.get('/corporate/wallet/list', params);
}

export function updateAdminWallet(params) {
    return request.patch('/corporate/wallet/update', params);
}

export function createReplacedTx(params) {
    return request.post('/corporate/tx/speedup', params);
}