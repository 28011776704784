import { Modal, Tabs, Result, List, Space, Typography, Avatar } from "antd";
import { conversionUtcDate } from "../../utils/comm";
import { UserOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";

const UserSuspiciousModalView = ({ user, open, onClose }) => {
    const [activeKey, setActiveKey] = useState('1');
    const tabItems = () => {
        return [
            {
                label: 'IPs',
                key: '1',
                children: getDataView('IPs'),
            },
            {
                label: 'Devices',
                key: '2',
                children: getDataView('devices'),
            },
            {
                label: 'Phones',
                key: '3',
                children: getDataView('phones'),
            },
            {
                label: 'Vpns',
                key: '4',
                children: getDataView('vpns'),
            },
        ]
    }

    useEffect(() => {
        if(open) setActiveKey('1');
    }, [open])

    const getDataView = (flag) => {
        const { suspiciousDetail } = user ?? {};
        let data = (suspiciousDetail ?? {})[flag];
        if (!data || data.length <= 0) {
            return (
                <Result title='No Data' />
            )
        }
        let dateKey = '';
        if (flag === 'IPs') dateKey = 'IP';
        else if (flag === 'devices') dateKey = 'device';
        else if (flag === 'phones') dateKey = 'phone';
        else if (flag === 'vpns') dateKey = 'vpn';

        return (
            <div style={{ maxHeight: '50vh', overflowY: 'auto' }}>
                <List
                    dataSource={data}
                    renderItem={(item) => {
                        return (
                            <List.Item key={item.username}>
                                <List.Item.Meta
                                    avatar={item.user.picture ?
                                        <Avatar size={48} src={item.user.picture} />
                                        :
                                        <Avatar size={48} icon={<UserOutlined />} style={{ backgroundColor: '#87d068' }} />}
                                    title={
                                        <Space>
                                            <div>{item.user.name}</div>
                                            {
                                                item.user.email && <Typography.Text style={{ fontSize: 12, color: '#afafaf' }} copyable>{item.user.email}</Typography.Text>
                                            }
                                        </Space>
                                    }
                                    description={
                                        <Space direction="vertical" size={[0, 0]}>
                                            {
                                                item.user.userID && <Typography.Text style={{ fontSize: 12, color: '#afafaf' }} copyable>UserID:&nbsp;&nbsp;{item.user.userID}</Typography.Text>
                                            }
                                            <Typography.Text style={{ fontSize: 12, color: '#afafaf' }}>{conversionUtcDate(item.createdAt)}</Typography.Text>
                                        </Space>
                                    }
                                />
                                <div style={{marginRight: 12}}>
                                    <Typography.Text mark copyable>
                                        {item[dateKey]}
                                    </Typography.Text>
                                </div>
                            </List.Item>
                        )
                    }}
                />
            </div>
        )
    }

    const getTitle = () => {
        if (!user) return 'Suspicious User';
        return <div style={{fontWeight: 400}}>Suspicious for <b>{user.name ?? user.username}</b></div>
    }

    return (
        <Modal title={getTitle()} width={720} onCancel={onClose} footer={null} open={open}>
            <Tabs items={tabItems()} activeKey={activeKey} onChange={setActiveKey} />
        </Modal>
    )
}

export default UserSuspiciousModalView;