import React, { useEffect } from 'react';
import * as echarts from 'echarts/core';
import {
    TitleComponent,
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent
} from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use([
    TitleComponent,
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    LineChart,
    CanvasRenderer,
    UniversalTransition
]);

const UserPlotsView = () => {
    let chartDom;
    let plotChart;
    useEffect(() => {
        chartDom = document.getElementById('plotChart');
        plotChart = echarts.init(chartDom);
        let option = {
            title: {
                text: 'Community Activity Trend (Last 15 Days)',
                textStyle: {
                    fontSize: 17,
                    fontWeight: 500,
                    color: '#1a3353'
                }
            },
            tooltip: {
                trigger: 'axis'
            },
            grid: {
                bottom: 20,
                left: 10,
                right: 40,
                top: 60,
            },
            legend: {
                lineStyle: {
                    color: '#ccc',
                },
                right: 0,
            },
            toolbox: {
                show: false,
                feature: {
                    dataZoom: {
                        yAxisIndex: 'none'
                    },
                    dataView: { readOnly: false },
                    magicType: { type: ['line', 'bar'] },
                    restore: {},
                    saveAsImage: {}
                }
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: [
                    '5/1',
                    '5/2',
                    '5/3',
                    '5/4',
                    '5/5',
                    '5/6',
                    '5/7',
                    '5/8',
                    '5/9',
                    '5/10',
                    '5/11',
                    '5/12',
                    '5/13',
                    '5/14',
                    '5/15'
                ],
                splitLine: {
                    show: false
                }
            },
            yAxis: {
                position: 'right',
                max: 50,
                type: 'value',
                splitLine: {
                    show: false
                },
                axisLabel: {
                    formatter: '{value}'
                }
            },
            series: [
                {
                    name: 'Daily New Registered',
                    type: 'line',
                    data: [10, 11, 13, 11, 12, 15, 17, 22, 18, 19, 21, 14, 16, 20, 25],
                    smooth: false,
                    symbol: 'circle',
                    symbolSize: 8,
                    showSymbol: false,
                    lineStyle: {
                        width: 2,
                        color: '#04d182'
                    }
                },
                {
                    name: 'Daily New Posts',
                    type: 'line',
                    data: [3, 8, 14, 16, 18, 15, 16, 20, 24, 22, 25, 27, 30, 35, 38],
                    smooth: false,
                    showSymbol: false,
                    symbol: 'circle',
                    symbolSize: 8,
                    lineStyle: {
                        width: 2,
                        color: 'rgb(62, 130, 247)'
                    }
                }
            ]
        };
        plotChart.setOption(option);
    }, []);


    return (
        <div id='plotChart' style={{ height: 300, width: '100%' }} />
    );
};

export default UserPlotsView;