import { Button, Space, Table, Pagination, Flex, Input, Typography, DatePicker, Select, Popconfirm, message, Image, Tag } from "antd";
import { useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { deleteTag, fetchAllTags, updateTag } from "../../api/community";
import { conversionUtcDate } from "../../utils/comm";
import { useLocation } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import { useNavigate } from 'react-router-dom';
import { MainLayoutUrl } from "../../router";
import PrizeImg from '../../assets/prize.png';
import dayjs from "dayjs";

const AllTagsScreen = () => {
    const location = useLocation();
    const navigateTo = useNavigate();
    const tabCols = [
        {
            title: 'Index',
            dataIndex: 'id',
            key: 'id',
            render: (text, _, index) => ((pageNum - 1) * pageSize + index + 1)
        },
        {
            title: 'Title',
            dataIndex: 'name',
            key: 'name',
            render: (text, _) => {
                return <b>{text}</b>
            },
        },
        {
            title: 'Tag',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => {
                return <Typography.Text mark>{`#${text}`}</Typography.Text>;
            },
        },
        {
            title: 'Reward',
            dataIndex: 'reward',
            key: 'reward',
            render: (text, _) => {
                if (!text.id || text.id === '0') return '-';
                return (
                    <Flex>
                        <Tag style={{ display: 'flex', alignItems: 'center', margin: 0 }}>
                            <img alt='post reward' src={PrizeImg} style={{ width: 16, height: 'auto', marginRight: 6 }} />
                            <b>{text.count} {text.symbol}</b>
                        </Tag>
                    </Flex>
                );
            },
        },
        {
            title: 'Cover',
            dataIndex: 'cover',
            key: 'cover',
            render: (text, record) => {
                if (record.reward && record.reward.cover) {
                    return <Image src={record.reward.cover} style={{ maxWidth: 120, maxHeight: 60, objectFit: 'contain' }} />
                } else if (record.cover) {
                    return <Image src={text} style={{ maxWidth: 120, maxHeight: 60, objectFit: 'contain' }} />
                }
                return '';
            },
        },
        {
            title: 'CreatedAt',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text, _) => {
                return <label style={{ fontSize: 12, color: '#afafaf' }}>{conversionUtcDate(text, null, true)}</label>
            },
        },
        {
            title: 'Post Count',
            dataIndex: 'postCount',
            key: 'postCount',
            render: (text, _) => {
                if (!text || parseInt(text) <= 0) return '-';
                return <b>{text}</b>
            },
        },
        {
            title: 'Shares',
            dataIndex: 'shareCount',
            key: 'shareCount',
            render: (text, _) => {
                if (!text || parseInt(text) <= 0) return '-';
                return <b>{text}</b>
            },
        },
        {
            title: 'PV',
            dataIndex: 'viewCount',
            key: 'viewCount',
            render: (text, _) => {
                if (!text || parseInt(text) <= 0) return '-';
                return <b>{text}</b>
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, _) => {
                if (String(text) === '0' || String(text).toLowerCase() === 'active') {
                    return <Tag color="success">{text}</Tag>
                }
                return <Tag color="error">{text}</Tag>
            },
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <Button.Group size="small">
                        <Button type='link' onClick={() => { activeHandle(record) }}>
                            {
                                String(record.status) === '0' || String(record.status).toLowerCase() === 'active' ?
                                    'Inactive' : 'Active'
                            }
                        </Button>
                        <Button type='link' onClick={() => { editHandle(record) }}>Edit</Button>
                        {/* <Popconfirm
                            title="Delete Action"
                            description="Are you sure to delete this tag?"
                            onConfirm={() => { deleteHandle(record) }}
                            okText="Yes"
                            cancelText="No" >
                            <Button danger type='link'>Delete</Button>
                        </Popconfirm> */}
                    </Button.Group>
                )
            },
        }
    ];

    const [searchParams, setSearchParams] = useState({
        name: '',
        status: '',
        start: '',
        end: '',
        level: 1,
    });

    const [tabData, setTabData] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchData(pageNum, pageSize, searchParams);
    }, [location]);

    const fetchData = (pNum, pSize, search) => {
        setLoading(true);
        let params = {
            'page.num': pNum,
            'page.size': pSize,
            'isTopicTag': true,
            ...search,
        };
        fetchAllTags(params).then(res => {
            if (res.page) {
                setTotal(res.page.total);
            }
            if (res.list) {
                setTabData(res.list);
            }
        }).finally(() => setLoading(false))
    }

    const pageChange = (page, size) => {
        setPageNum(page);
        setPageSize(size);
        fetchData(page, size, searchParams);
    }

    const onSearchChange = useDebouncedCallback(
        (tag, value) => {
            let newParams = { ...searchParams };
            if (tag === 'dates') {
                if (value.length == 2) {
                    newParams.start = `${value[0]}T00:00:00Z`;
                    newParams.end = `${value[1]}T23:59:59Z`;
                } else {
                    newParams.start = '';
                    newParams.end = '';
                }
            } else {
                newParams[tag] = value;
            }
            setSearchParams(newParams);
            setPageNum(1);
            fetchData(1, pageSize, newParams);
        },
        300
    )

    const createNewTag = () => {
        navigateTo(MainLayoutUrl.community.tags.item.path.replace(':id', '0'));
    }

    const deleteHandle = (item) => {
        setLoading(true);
        deleteTag(item.id).then(res => {
            setLoading(false);
            fetchData(pageNum, pageSize, searchParams);
        }).catch(err => {
            setLoading(false);
            message.error(String(err));
        })
    }

    const editHandle = (item) => {
        navigateTo(MainLayoutUrl.community.tags.item.path.replace(':id', item.id), { state: { detail: JSON.stringify(item) } });
    }

    const activeHandle = (record) => {
        setLoading(true);
        let status = String(record.status) === '0' || String(record.status).toLowerCase() === 'active' ? 1 : 0;
        updateTag({ list: [{ ...record, status: status }] }).then(res => {
            setLoading(false);
            fetchData(pageNum, pageSize, searchParams);
        }).catch(err => {
            setLoading(false);
            message.error(String(err));
        })
    }

    return (
        <div className='main-wrapper'>
            <div className='top-part cnt-item'>
                <Flex justify='space-between'>
                    <Button type='primary' icon={<PlusOutlined />} onClick={createNewTag}>Create Tag</Button>
                    <Space>
                        <Input
                            placeholder="keywords search"
                            allowClear
                            onChange={evt => onSearchChange('name', evt.target.value)}
                        />
                        <Select
                            style={{ width: 160 }}
                            options={[
                                { key: 1, label: 'Active', value: 0 },
                                { key: 2, label: 'Inactive', value: 1 },
                            ]}
                            placeholder='status'
                            onChange={value => onSearchChange('status', value)}
                            allowClear
                        />
                        <DatePicker.RangePicker onChange={values => onSearchChange('dates', (values ?? []).map(date => dayjs(date).format('YYYY-MM-DD')))} />
                    </Space>
                </Flex>
            </div>
            <div className='cnt-item'>
                <Table
                    scroll={{ x: true }}
                    loading={loading}
                    rowKey={(record) => record.id}
                    rowClassName={'table-row'}
                    size='small'
                    dataSource={tabData}
                    columns={tabCols}
                    pagination={false}
                    expandable={{ rowExpandable: (record) => false, showExpandColumn: false }} />
            </div>
            <div className='footer-part cnt-item'>
                <Pagination
                    total={total}
                    showTotal={(total) => `total ${total}`}
                    current={pageNum}
                    pageSize={pageSize}
                    showSizeChanger={true}
                    pageSizeOptions={[10, 20, 30, 50]}
                    onChange={(page, size) => pageChange(page, size)}
                />
            </div>
        </div>
    )
}

export default AllTagsScreen;