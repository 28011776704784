import { Input, Button, message, Select, Form, Row, Col, Space, Avatar, Spin, Typography, Radio, Modal } from 'antd';
import { createVirtualComment, fetchVirtualUsers } from '../../api/community';
import { useEffect, useMemo, useState } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { useDebouncedCallback } from 'use-debounce';
import { CloudStorageImageUploader } from '../upload';
import { customUploaderBtn } from '../comm/custom_uploader_btn';

const VirtualCommentForPostModalView = ({ open, post, replyComment, onClose, onUpdate }) => {
    const [loading, setLoading] = useState(false);
    const [searchLoading, setSearchLoading] = useState(false);
    const [form] = Form.useForm();
    const [showUserSelect, setShowUserSelect] = useState(false);
    const [users, setUsers] = useState([]);
    const [resources, setResources] = useState([])

    useEffect(() => {
        resetFields();
        handleUserSearch('');
    }, [open]);

    const resetFields = () => {
        form.setFieldValue('userId', null)
        form.setFieldValue('content', '')
        form.setFieldValue('userType', showUserSelect ? 2 : 1)
        setResources([]);
    }

    const onSubmit = () => {
        let params = {
            repliedId: replyComment?.id,
            postId: post?.id,
            userId: form.getFieldValue('userId'),
            content: form.getFieldValue('content'),
            resources: resources.map(item => ({url: item}))
        };
        if (!params.postId) {
            message.warning('Please choose a post');
            return;
        }
        setLoading(true);
        if (params.id) {

        } else {
            createVirtualComment(params).then(res => {
                resetFields();

                onClose();
                onUpdate();
            }).finally(() => {
                setLoading(false);
            })
        }
    }

    const onUserSelectChange = (value) => {
        form.setFieldValue('userId', value);
    }

    const handleUserSearch = useDebouncedCallback(
        (keyword) => {
            let params = {
                'page.num': 1,
                'page.size': 10,
                'keyword': keyword,
            }
            setSearchLoading(true);
            fetchVirtualUsers(params).then(res => {
                if (res.list) {
                    setUsers(res.list.map(item => ({ ...item, label: item.name, value: item.userId })));
                }
            }).finally(() => setSearchLoading(false))
        },
        300
    )

    const drawTitle = () => {
        if(replyComment) {
            return `Reply @${replyComment.user.name ?? replyComment.user.username}`
        }
        return 'Add new comment for the post'
    }

    const onUserTypeChange = (value) => {
        if (value === 1) {
            setShowUserSelect(false);
            form.setFieldValue('userId', null);
        } else {
            setShowUserSelect(true);
        }
    }

    const onTagCoverImageUpdate = (files) => {
        if (files[0]) {
            setResources([files[0].remoteUrl ? files[0].remoteUrl : files[0].url])
        } else {
            setResources([])
        }
    }

    const virtualCommentUploadProps = {
        defaultFileList: useMemo(() => {
            return resources && resources[0] ? [{
                name: (decodeURIComponent(resources[0]).split('/').pop() ?? '').split('?')[0],
                url: resources[0],
                percent: 100,
            }] : [];
        }, [resources]),
        name: 'virtual-comment-uploader',
        bucketPath: '/virtual-comment',
        listType: 'picture-card',
        isMultiple: false,
        needItemRender: false,
        useCardPreview: true,
        showUploadList: true,
        buttonNode: customUploaderBtn('Upload Image'),
        onUpdate: onTagCoverImageUpdate
    }

    return (
        <div>
            <Modal
                title={drawTitle()}
                width={480}
                zIndex={111111}
                mask={false}
                onCancel={onClose}
                open={open}
                styles={{
                    body: {
                        paddingTop: 32,
                    },
                }}
                footer={
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button onClick={onSubmit} type="primary" loading={loading}>
                            Submit
                        </Button>
                    </Space>
                }
            >
                <Form
                    layout="vertical"
                    form={form}
                    requiredMark={false} >

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='userType' label='User Type'>
                                <Radio.Group onChange={(evt) => onUserTypeChange(evt.target.value)}>
                                    <Radio.Button value={1}>Random</Radio.Button>
                                    <Radio.Button value={2}>Specify</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>

                    {
                        showUserSelect &&
                        (
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item name='userId' label='Virtual User'>
                                        <Select
                                            style={{ width: '100%' }}
                                            size='large'
                                            placeholder='you can input keywords to search...'
                                            defaultActiveFirstOption={false}
                                            suffixIcon={null}
                                            filterOption={false}
                                            onSearch={handleUserSearch}
                                            notFoundContent={searchLoading ? <Spin /> : null}
                                            options={users}
                                            optionRender={(option) => {
                                                return (
                                                    <Space>
                                                        {
                                                            option.data.picture ?
                                                                <Avatar size={32} src={option.data.picture} />
                                                                :
                                                                <Avatar size={32} style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />
                                                        }
                                                        <div style={{ flex: 1 }}>
                                                            <b>{option.data.name}</b>
                                                            <div>
                                                                <Typography.Text style={{ color: '#999', fontSize: 12 }}>
                                                                    {option.data.username}
                                                                </Typography.Text>
                                                            </div>
                                                        </div>
                                                    </Space>
                                                )
                                            }}
                                            onChange={onUserSelectChange}
                                            allowClear
                                            showSearch
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        )
                    }
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='content' label='Comment Content'>
                                <Input.TextArea rows={5} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='resource' label='Comment Image'>
                                <CloudStorageImageUploader {...virtualCommentUploadProps} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>

            <div style={{ display: 'none' }} id='contentHolder' />
        </div>
    )
}

export default VirtualCommentForPostModalView;