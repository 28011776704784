import { Space, Drawer, Button, Col, Form, Row, Cascader, Input, Switch, message } from "antd";
import { useEffect, useState, useMemo } from "react";
import { CloudStorageImageUploader } from "../upload";
import { PlusOutlined } from '@ant-design/icons';
import { updateCatalogs } from "../../api/course";
import { customUploaderBtn } from "../comm/custom_uploader_btn";

const CatalogEditView = ({ open, defaultCatalog, categories, onClose, onUpdate }) => {
    const [options, setOptions] = useState([]);
    const [catalog, setCatalog] = useState({});
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    useEffect(() => {
        if (categories && categories.length > 0) {
            setOptions(categories.map(item => {
                return {
                    value: item.id,
                    label: item.name,
                    children: item.children.map(ch => ({
                        value: ch.id,
                        label: ch.name,
                    }))
                };
            }))
        }
    }, [categories])

    useEffect(() => {
        setCatalog(defaultCatalog ?? {});
        if (defaultCatalog && Object.keys(defaultCatalog).length > 0) {
            let index = categories.findIndex(item => item.children.map(ch => ch.id).includes(defaultCatalog.categoryId));
            if (index >= 0) {
                form.setFieldValue('categoryId', [categories[index].id, defaultCatalog.categoryId]);
            }
            form.setFieldValue('title', defaultCatalog.title);
            form.setFieldValue('recommend', defaultCatalog.recommend);
            form.setFieldValue('banner', defaultCatalog.banner);
            form.setFieldValue('detail', defaultCatalog.detail);
            form.setFieldValue('learningCount', defaultCatalog.learningCount);
        } else {
            form.setFieldValue('categoryId', []);
            form.setFieldValue('title', '');
            form.setFieldValue('recommend', false);
            form.setFieldValue('banner', false);
            form.setFieldValue('detail', '');
            form.setFieldValue('learningCount', '');
        }
    }, [defaultCatalog])

    useEffect(() => {
        if (!open) {
            form.setFieldValue('categoryId', [])
            form.setFieldValue('title', '')
            form.setFieldValue('recommend', false);
            form.setFieldValue('banner', false);
            form.setFieldValue('detail', '');
            form.setFieldValue('learningCount', '');
        }
    }, [open])

    const onImageUpdate = (flag, data) => {
        if (!data || data.length <= 0) return;
        let newCatalog = { ...catalog };
        newCatalog[flag] = data[0].remoteUrl;
        setCatalog(newCatalog);
    }

    const onSubmit = () => {
        let categoryId = Array.from(form.getFieldValue('categoryId') ?? []).pop();
        let learningCount = form.getFieldValue('learningCount');
        let params = {
            ...catalog,
            title: form.getFieldValue('title'),
            categoryId: categoryId,
            recommend: form.getFieldValue('recommend') ?? false,
            banner: form.getFieldValue('banner') ?? false,
            detail: form.getFieldValue('detail'),
            learningCount: learningCount && String(learningCount).trim() ?  String(learningCount).trim() : '0',
        };
        if (!params.title || !params.categoryId || !params.previewImage) {
            message.warning('Incomplete information');
            return;
        }
        setLoading(true);
        updateCatalogs({ list: [params] }).then(res => {
            setLoading(false);
            setCatalog({});
            onClose();
            onUpdate();
        });
    }

    const uploadPreviewProps = {
        name: 'calalog-uploader',
        bucketPath: '/course',
        defaultFileList: useMemo(() => {
            return catalog && catalog.previewImage ? [{
                name: (decodeURIComponent(catalog.previewImage).split('/').pop() ?? '').split('?')[0],
                url: catalog.previewImage,
                percent: 100,
            }] : [];
        }, [catalog.previewImage]),
        showUploadList: { showRemoveIcon: false },
        isMultiple: false,
        listType: 'picture-card',
        needItemRender: false,
        buttonNode: customUploaderBtn('Upload Cover'),
        onUpdate: (data) => onImageUpdate('previewImage', data),
    }

    const uploadBannerProps = {
        name: 'calalog-banner-uploader',
        bucketPath: '/course',
        defaultFileList: useMemo(() => {
            return catalog && catalog.bannerImage ? [{
                name: (decodeURIComponent(catalog.bannerImage).split('/').pop() ?? '').split('?')[0],
                url: catalog.bannerImage,
                percent: 100,
            }] : []
        }, [catalog.bannerImage]),
        showUploadList: { showRemoveIcon: false },
        isMultiple: false,
        listType: 'picture-card',
        needItemRender: false,
        buttonNode: customUploaderBtn('Upload Banner'),
        onUpdate: (data) => onImageUpdate('bannerImage', data),
    }

    return (
        <div>
            <Drawer
                forceRender={true}
                title={defaultCatalog ? 'Edit catalog' : 'Create catalog'}
                width={480}
                maskClosable
                onClose={onClose}
                open={open}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                extra={
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button onClick={onSubmit} type="primary" loading={loading}>
                            Submit
                        </Button>
                    </Space>
                }
            >
                <Form
                    layout="vertical"
                    form={form}
                    requiredMark={false} >
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='title' label='Title'>
                                <Input placeholder="catalog title" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='categoryId' label='Category'>
                                <Cascader options={options} placeholder='please select category' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='recommend' label='Is Recommend'>
                                <Switch />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='banner' label='Is Banner'>
                                <Switch />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='learningCount' label='Learner Count'>
                                <Input placeholder="learner count" type="number" />
                            </Form.Item>
                        </Col>
                    </Row>
                    {
                        open && <Row gutter={16}>
                            <Col span={24}>
                                <div style={{ fontSize: 14, color: 'rgba(0,0,0,0.88)', paddingBottom: 12 }}>Preview Image</div>
                                <CloudStorageImageUploader {...uploadPreviewProps} />
                                <div style={{height: 16}} />
                            </Col>
                        </Row>
                    }
                    {
                        open && <Row gutter={16}>
                            <Col span={24}>
                                <div style={{ fontSize: 14, color: 'rgba(0,0,0,0.88)', paddingBottom: 12 }}>Banner Image</div>
                                <CloudStorageImageUploader {...uploadBannerProps} />
                                <div style={{height: 16}} />
                            </Col>
                        </Row>
                    }
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='detail' label='Description'>
                                <Input.TextArea placeholder="description" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>
        </div>
    )
}

export default CatalogEditView;