import { useEffect, useState } from 'react'
import { Button, Table, Pagination, Flex, Input, Space } from 'antd';
import UserInfoView from '../../components/community/user_info_view';
import { useDebouncedCallback } from 'use-debounce';
import { fetchPointsBalance } from '../../api/points';
import PointsHistoryView from '../../components/points/points_history_view';

const PointBalanceScreen = () => {
    const [tabData, setTabData] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [currUser, setCurrUser] = useState();
    const [keyword, setKeyword] = useState();

    const tabCols = [
        {
            title: 'Index',
            dataIndex: 'id',
            key: 'id',
            render: (text, _, index) => ((pageNum - 1) * pageSize + index + 1),
        },
        {
            title: 'User Info',
            dataIndex: 'user',
            key: 'user',
            render: (_, record) => {
                return <UserInfoView user={record.user} />
            }
        },
        {
            title: 'Current Points',
            dataIndex: 'point',
            key: 'point',
            render: (text, _) => {
                return <b>{text}</b>
            }
        },
        {
            title: 'Redeemed Points',
            dataIndex: 'redeemPoint',
            key: 'redeemPoint',
            render: (text, _) => {
                return <b>{text}</b>
            }
        },
        {
            title: 'Total Points',
            dataIndex: 'totalPoint',
            key: 'totalPoint',
            render: (_, record) => {
                return <b>{parseInt(record.point) + parseInt(record.redeemPoint)}</b>
            }
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <Button.Group size='small'>
                        <Button type='link' onClick={() => { checkHistory(record) }}>Balance History</Button>
                    </Button.Group>
                )
            }
        }
    ];

    useEffect(() => {
        setLoading(true);
        getUsers(pageNum, pageSize, keyword);
    }, [])

    const getUsers = useDebouncedCallback(
        (pNum, pSize, keyword) => {
            let params = {
                'page.num': pNum,
                'page.size': pSize,
                'userId': keyword,
            }
            setLoading(true);
            fetchPointsBalance(params).then(res => {
                if (res.list) {
                    setTabData(res.list);
                }
                if (res.page) {
                    setTotal(res.page.total);
                }
            }).finally(() => setLoading(false))
        },
        300
    )

    const pageChange = (page, size) => {
        setPageNum(page);
        setPageSize(size);
        getUsers(page, size, keyword);
    }

    const checkHistory = (record) => {
        setCurrUser(record);
        setOpen(true);
    }

    const onKeywordChange = (value) => {
        setKeyword(value);
        setPageNum(1);
        getUsers(1, pageSize, value);
    }

    return (
        <div className='main-wrapper'>
            <div className='top-part cnt-item'>
                <Flex justify='space-between'>
                    <div />
                    <Space>
                        <Input
                            placeholder="keywords search"
                            allowClear
                            onChange={evt => onKeywordChange(evt.target.value)}
                        />
                    </Space>
                </Flex>
            </div>
            <div className='cnt-item'>
                <Table loading={loading} rowKey={(record) => record.id} rowClassName={'table-row'} size='small' dataSource={tabData} columns={tabCols} pagination={false} />
            </div>
            <div className='footer-part cnt-item'>
                <Pagination
                    total={total}
                    showTotal={(total) => `total ${total}`}
                    current={pageNum}
                    pageSize={pageSize}
                    showSizeChanger={true}
                    pageSizeOptions={[10, 20, 30, 50]}
                    onChange={(page, size) => pageChange(page, size)}
                />
            </div>
            <PointsHistoryView open={open} user={currUser} onClose={() => {
                setOpen(false);
                setCurrUser(null);
            }} />
        </div>
    )
}
export default PointBalanceScreen;