import { Popover, Space, Tag, Flex, Radio } from "antd";
import { useEffect, useState } from "react";


export const UserTagPermissionOptions = [
    { key: 0, label: 'None', value: '0' },
    { key: 1, label: 'TopPage', value: '1' },
];

const UserTagItemScopeView = ({ defaultTags, onUpdate }) => {
    const [tags, setTags] = useState([]);
    const [popOpen, setPopOpen] = useState(false);
    const [currLabelId, setCurrLabelId] = useState(null);

    useEffect(() => {
        setTags(defaultTags ?? []);
    }, [defaultTags])

    const onChange = (index, value) => {
        let newTags = [...tags];
        newTags[index].permissionType = value;
        setTags(newTags);
        setCurrLabelId(null);
        setPopOpen(false);
        onUpdate(newTags);
    }

    const onOpenChange = (value) => {
        setPopOpen(value);
    }
    return (
        <Flex gap="4px 0" wrap="wrap">
            {
                Array.from(tags).filter(item => item.id !== '-1').map((item, index) => {
                    return (
                        <Popover
                            open={popOpen && currLabelId === item.id}
                            title='Label Permission'
                            key={item.id}
                            placement="top"
                            trigger={'click'}
                            content={
                                <Radio.Group onChange={(evt) => onChange(index, evt.target.value)} value={item.permissionType} >
                                    <Space direction="vertical">
                                        {
                                            UserTagPermissionOptions.map(ptype => {
                                                return (
                                                    <Radio key={ptype.key} value={ptype.label}>{ptype.label}</Radio>
                                                )
                                            })
                                        }
                                    </Space>
                                </Radio.Group>
                            }
                            onOpenChange={onOpenChange}>
                            <Tag
                                color='green'
                                key={item.id}
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    setCurrLabelId(item.id);
                                    setPopOpen(true);
                                }}>
                                <b>{item.name}</b>
                                {
                                    item.permissionType !== 'None' ?
                                        `(${item.permissionType})`
                                        :
                                        ''
                                }
                            </Tag>
                        </Popover>
                    )
                })
            }
        </Flex>


    )
}

export default UserTagItemScopeView;