import { Input, Button, message, Select, Form, Row, Col, Drawer, Space, Avatar, Spin, Typography } from 'antd';
import { createRecommendUser, fetchUserSearch, updateRecommendUser } from '../../api/community';
import { useEffect, useState } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { useDebouncedCallback } from 'use-debounce';

const RecommendEditView = ({ open, defaultUser, onClose, onUpdate }) => {
    const [loading, setLoading] = useState(false);
    const [searchLoading, setSearchLoading] = useState(false);
    const [form] = Form.useForm();
    const [users, setUsers] = useState([]);
    const [data, setData] = useState({
        userId: '',
        description: '',
        weight: '',
    });

    useEffect(() => {
        let user = defaultUser ? defaultUser.user : null;
        setData(defaultUser);
        if (user) {
            form.setFieldValue('userId', user.userId)
            form.setFieldValue('weight', defaultUser.weight);
            form.setFieldValue('description', defaultUser.description);
            fetchUsers(user ? (user.name ? user.name : user.email) : '');
        } else {
            form.setFieldValue('userId', null)
            form.setFieldValue('weight', '');
            form.setFieldValue('description', '');
            fetchUsers('');
        }
    }, [defaultUser]);

    useEffect(() => {
        if (!open) {
            form.setFieldValue('userId', null);
            form.setFieldValue('weight', '');
            form.setFieldValue('description', '');
        }
    }, [open])

    const onSubmit = () => {
        let params = {
            ...data,
            userId: form.getFieldValue('userId'),
            weight: form.getFieldValue('weight'),
            description: form.getFieldValue('description'),
        };
        if (!params.userId || !params.weight) {
            message.warning('Incomplete information');
            return;
        }
        setLoading(true);
        if (params.id) {
            updateRecommendUser(params).then(res => {
                onClose();
                onUpdate();
            }).catch(err => {
                message.error(String(err));
            }).finally(() => {
                setLoading(false);
            })
        } else {
            createRecommendUser(params).then(res => {
                onClose();
                onUpdate();
            }).catch(err => {
                message.error(String(err));
            }).finally(() => {
                setLoading(false);
            })
        }
    }

    const handleSearch = (value) => {
        fetchUsers(value);
    }

    const fetchUsers = useDebouncedCallback(
        (keyword) => {
            let params = {
                'page.num': 1,
                'page.size': 10,
                'keyword': keyword,
            }
            setSearchLoading(true);
            fetchUserSearch(params).then(res => {
                if (res.list) {
                    let tmp = res.list.map(item => ({
                        avatar: (item.picture ? item.picture : item.avatar),
                        email: item.email,
                        label: item.name,
                        value: item.userId,
                    }));
                    setUsers(tmp);
                }
            }).finally(() => setSearchLoading(false))
        },
        300
    )

    return (
        <div>
            <Drawer
                forceRender={true}
                title={defaultUser ? 'Edit Recommend User' : 'Add New Recommend User'}
                width={480}
                maskClosable
                onClose={onClose}
                open={open}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                extra={
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button onClick={onSubmit} type="primary" loading={loading}>
                            Submit
                        </Button>
                    </Space>
                }
            >
                <Form
                    layout="vertical"
                    form={form}
                    requiredMark={false} >
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='userId' label='User'>
                                <Select
                                    disabled={defaultUser}
                                    placeholder='select user'
                                    defaultActiveFirstOption={false}
                                    suffixIcon={null}
                                    filterOption={false}
                                    onSearch={handleSearch}
                                    notFoundContent={searchLoading ? <Spin /> : null}
                                    options={users}
                                    optionRender={(option) => {
                                        return (
                                            <Space>
                                                {
                                                    option.data.avatar ?
                                                        <Avatar size={32} src={option.data.avatar} />
                                                        :
                                                        <Avatar size={32} style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />
                                                }
                                                <div>
                                                    <b>{option.data.label ? option.data.label : 'anonymous'}</b>
                                                    <div>
                                                        <Typography.Text style={{color: '#999', fontSize: 12}}>{option.data.email ?? option.data.value}</Typography.Text>
                                                    </div>
                                                </div>
                                            </Space>
                                        )
                                    }}
                                    allowClear
                                    showSearch
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='weight' label='Weight'>
                                <Input placeholder="recommend weight" type='number' allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='description' label='Description'>
                                <Input placeholder="description" allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>
        </div>
    )
}

export default RecommendEditView;