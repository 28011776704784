import { Descriptions, Input, DatePicker, Space, Button, Radio, message } from "antd";
import { CloudStorageImageUploader } from "../../components/upload";
import { useMemo, useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { customUploaderBtn } from "../../components/comm/custom_uploader_btn";
import { conversionUtcDate } from "../../utils/comm";
import { createTag, updateTag } from '../../api/community';
import { MainLayoutUrl } from "../../router";

const TagTemplate = {
    name: null,
    isTopicTag: true,
    reward: {},
    level: 1,
    cover: '',
    type: 0,
    description: '',
    children: []
}

const TopicTagBasicInfoView = () => {
    const navigateTo = useNavigate();
    const { id } = useParams();
    const location = useLocation();

    const defaultData = (location.state && location.state.detail ? JSON.parse(location.state.detail) : TagTemplate);
    const [data, setData] = useState({ ...defaultData, type: parseInt(defaultData.reward.id) > 0 ? 1 : 0 });
    const [loading, setLoading] = useState(false);

    const onFormChange = (tag, value) => {
        let newData = { ...data };
        if (tag.includes('reward')) {
            let reward = { ...(data.reward ?? {}) }
            if (tag.includes('count')) {
                reward['count'] = value;
            } else if (tag.includes('endAt')) {
                reward['endAt'] = conversionUtcDate(value, 'UTC');
            } else if (tag.includes('desc')) {
                reward['description'] = value;
            } else if (tag.includes('tip')) {
                reward['tip'] = value;
            } else if (tag.includes('rule')) {
                reward['rule'] = value;
            } else if (tag.includes('symbol')) {
                reward['symbol'] = value;
            }
            newData['reward'] = reward;
        } else {
            newData[tag] = value;
        }

        setData(newData);
    }

    const onTagCoverImageUpdate = (files) => {
        let newData = { ...data };
        if (data.type && parseInt(data.type) === 1) {
            let reward = newData.reward ? { ...newData.reward } : {};
            reward['cover'] = files[0] ? (files[0].remoteUrl ? files[0].remoteUrl : files[0].url) : '';
            newData.reward = reward;
        } else {
            newData['cover'] = files[0] ? (files[0].remoteUrl ? files[0].remoteUrl : files[0].url) : '';
        }
        setData(newData);
    }

    const tagCoverUploadProps = {
        defaultFileList: useMemo(() => {
            let cover = data.reward?.cover ? data.reward?.cover : data.cover;
            return cover ? [{
                name: (decodeURIComponent(cover).split('/').pop() ?? '').split('?')[0],
                url: cover,
                percent: 100,
            }] : [];
        }, [data.cover, data.reward?.cover]),
        name: 'tag-cover-uploader',
        bucketPath: '/tags',
        listType: 'picture-card',
        isMultiple: false,
        needItemRender: true,
        useCardPreview: true,
        showUploadList: true,
        buttonNode: customUploaderBtn('Upload Cover'),
        onUpdate: onTagCoverImageUpdate
    }

    const btns = () => {
        return (
            <Space>
                <Button loading={loading} type="primary" onClick={toSubmit}>Submit</Button>
                <Button loading={loading} block onClick={toCancel}>Cancel</Button>
            </Space>
        )
    }

    const toSubmit = () => {
        setLoading(true);
        let params = {
            ...data
        }
        if(params.type === 0) {
            delete params['reward'];
        } else {
            params.cover = '';
        }
        if (defaultData.id) {
            updateTag({ list: [params] }).then(res => {
                if(res) {
                    message.success('saved!');
                    navigateTo(MainLayoutUrl.community.tags.index.path);
                }
            }).finally(() => {
                setLoading(false);
            })
        } else {
            createTag(params).then(res => {
                if(res) {
                    message.success('saved!');
                    navigateTo(MainLayoutUrl.community.tags.index.path);
                }
            }).finally(() => {
                setLoading(false);
            })
        }
    }

    const toCancel = () => {
        navigateTo(-1);
    }

    return (
        <div style={{ padding: 20 }}>
            <Descriptions
                title={parseInt(id) > 0 ? 'Edit Topic Tag' : 'Create Topic Tag'}
                size='large'
                bordered
                column={1}
                labelStyle={{ width: 250, textAlign: 'center' }}
                colon={true}
                extra={btns()} >
                <Descriptions.Item span={1} label='Topic Tag'>
                    <Input
                        style={{ width: '100%' }}
                        variant="filled"
                        value={data.name ? data.name : ''}
                        placeholder="tag name"
                        onChange={evt => onFormChange('name', evt.target.value)}
                        allowClear
                    />
                </Descriptions.Item>
                <Descriptions.Item span={1} label='Category'>
                    <Radio.Group 
                        disabled={data.id && parseInt(data.id) > 0}
                        buttonStyle='solid' 
                        value={data.type ? data.type : 0} 
                        onChange={evt => onFormChange('type', evt.target.value)} >
                        <Radio.Button value={0}>General</Radio.Button>
                        <Radio.Button value={1}>Bounty</Radio.Button>
                    </Radio.Group>
                </Descriptions.Item>
                <Descriptions.Item label='Cover' span={1}>
                    <CloudStorageImageUploader {...tagCoverUploadProps} />
                </Descriptions.Item>
                {
                    data.type !== 1 && <Descriptions.Item span={1} label='Description'>
                        <Input.TextArea
                            style={{ width: '100%' }}
                            variant="filled"
                            rows={6}
                            value={data.description ? data.description : ''}
                            placeholder="tag description"
                            onChange={evt => onFormChange('description', evt.target.value)}
                            allowClear
                        />
                    </Descriptions.Item>
                }
                {
                    data.type === 1 && (
                        <>
                            <Descriptions.Item span={1} label='Reward Amounts'>
                                <Input
                                    style={{ width: 200 }}
                                    variant="filled"
                                    value={data.reward ? data.reward.count : ''}
                                    type="number"
                                    placeholder="reward count"
                                    onChange={evt => onFormChange('reward_count', evt.target.value)}
                                    allowClear
                                />
                            </Descriptions.Item>
                            <Descriptions.Item span={1} label='Reward Symbol'>
                                <Input
                                    style={{ width: 200 }}
                                    variant="filled"
                                    value={data.reward ? data.reward.symbol : ''}
                                    placeholder="reward symbol"
                                    onChange={evt => onFormChange('reward_symbol', evt.target.value)}
                                    allowClear
                                />
                            </Descriptions.Item>
                            <Descriptions.Item span={1} label='End At'>
                                <DatePicker
                                    variant="filled"
                                    showTime
                                    minDate={data.createdAt ? dayjs(data.createdAt).add(1, 'day') : dayjs(Date.now()).add(1, 'day')}
                                    defaultValue={(data.reward && data.reward.endAt) ? dayjs(data.reward.endAt) : ''}
                                    format={(value) => `${value ? conversionUtcDate(value) : ''}`}
                                    placeholder="End At"
                                    onChange={(_, date) => onFormChange('reward_endAt', date.replace('End At: ', ''))}
                                />
                            </Descriptions.Item>
                            <Descriptions.Item label='Descrption' span={1}>
                                <Input.TextArea
                                    style={{ width: '100%' }}
                                    placeholder="reward descrption"
                                    value={data.reward ? data.reward.description : ''}
                                    rows={6}
                                    variant="filled"
                                    onChange={evt => onFormChange('reward_desc', evt.target.value)}
                                    allowClear
                                />
                            </Descriptions.Item>
                            <Descriptions.Item label='Tips' span={1}>
                                <Input.TextArea
                                    style={{ width: '100%' }}
                                    placeholder="reward tips"
                                    value={data.reward ? data.reward.tip : ''}
                                    rows={6}
                                    variant="filled"
                                    onChange={evt => onFormChange('reward_tip', evt.target.value)}
                                    allowClear
                                />
                            </Descriptions.Item>
                            <Descriptions.Item label='Rules' span={1}>
                                <Input.TextArea
                                    style={{ width: '100%' }}
                                    placeholder="reward rules"
                                    value={data.reward ? data.reward.rule : ''}
                                    rows={6}
                                    variant="filled"
                                    onChange={evt => onFormChange('reward_rule', evt.target.value)}
                                    allowClear
                                />
                            </Descriptions.Item>
                        </>
                    )
                }

            </Descriptions>
        </div>
    )
}

export default TopicTagBasicInfoView;