import { Card, Flex, Space, Avatar } from "antd";

const HotUserView = () => {
    const hotUsers = [
        {
            name: 'Terence',
            email: 'treza9527@gmail.com',
            avatar: '/images/thumb1.jpg',
            hotValue: '966',
        },
        {
            name: 'Hippo',
            avatar: '/images/thumb2.jpg',
            email: 'hizzytahizzytahizzytahizzyta@gmail.com',
            hotValue: '724',
        },
        {
            name: 'Zhou Ming',
            avatar: '/images/thumb2.jpg',
            email: 'zhou_min@gmail.com',
            hotValue: '519',
        },
        {
            name: 'Huang Yu',
            avatar: '/images/thumb1.jpg',
            email: 'huangming@gmail.com',
            hotValue: '374',
        },
        {
            name: 'David Ron',
            avatar: '/images/thumb2.jpg',
            email: 'pizza_zhou@gmail.com',
            hotValue: '302',
        }
    ];
    return (
        <Card style={{ height: '100%' }} styles={{ body: { padding: 20, height: '100%' } }}>
            <div className="static-title" style={{ marginBottom: 12, fontWeight: 700 }}>Hot Users Top5</div>
            {
                hotUsers.map((item, index) => {
                    return (
                        <Flex key={index} style={{ paddingTop: 24 }} align="center">
                            <Space size={4} style={{ flex: 1 }}>
                                <Avatar size={36} src={item.avatar} />
                                <div className="static-tips">
                                    <div className="hot-user-lab" style={{ color: '#1a3353' }}>{item.name}</div>
                                    <div className="hot-user-lab" style={{ fontSize: 12, fontWeight: 400 }}>{item.email}</div>
                                </div>
                            </Space>
                            <div style={{ marginLeft: 10 }}><b>{item.hotValue}</b></div>
                        </Flex>
                    )
                })
            }
        </Card>
    )
}

export default HotUserView;