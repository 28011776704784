import { useEffect, useState } from 'react'
import { fetchAppealList, updateAppeal } from '../../api/community';
import { Modal, Button, Table, Pagination, Flex, Input, Typography, Popconfirm } from 'antd';
import dayjs from 'dayjs';
import { message } from 'antd';
import { useDebouncedCallback } from 'use-debounce';
import UserInfoView from '../../components/community/user_info_view';
import PostReview from '../../components/community/post_review';
import { RichText } from '@chatui/core';

const AppealView = () => {
    const [tabData, setTabData] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [currPost, setCurrPost] = useState();
    const [open, setOpen] = useState(false);
    const [searchParams, setSearchParams] = useState({
        description: '',
        status: '',
    });

    const tabCols = [
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text, _) => {
                return <label style={{ fontSize: 12, color: '#afafaf' }}>{dayjs(text).format('YYYY-MM-DD HH:mm')}</label>
            }
        },
        {
            title: 'Author',
            dataIndex: 'user',
            key: 'user',
            render: (text, _) => {
                return <UserInfoView user={text} showWallet={false} />
            }
        },
        {
            title: 'Post Content',
            dataIndex: 'target',
            key: 'target',
            render: (_, record) => {
                let post = record.post;
                return <div style={{ background: '#f1f1f1', padding: '8px 12px', borderRadius: 8, width: 240 }}>
                    <div className="community-content">
                        <RichText content={post.title ? post.title : post.content} />
                    </div>
                </div>
            }
        },
        {
            title: 'Appeal Desc',
            dataIndex: 'description',
            key: 'description',
            render: (text, _) => {
                return (
                    <div style={{ fontSize: 12, color: 'grey' }}>
                        {text}
                    </div>
                )
            },
        },
        {
            title: 'Report Status',
            dataIndex: 'status',
            key: 'status',
            render: (_, record) => {
                if (record.status === 'Pending') {
                    return <Typography.Text type='warning'>Pending</Typography.Text>
                } else if (record.status === 'Success') {
                    return <Typography.Text type='success'>Approved</Typography.Text>
                } else if (record.status === 'Fail') {
                    return <Typography.Text type='danger'>Disapproved</Typography.Text>
                }
                return '';
            }
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <Button.Group size='small'>
                        {
                            record.status === 'Pending' && <Popconfirm
                                title='Please handle the appeal'
                                okText='Approve'
                                cancelText='Reject'
                                onCancel={() => approveHandle(record, 'Fail')}
                                onConfirm={() => approveHandle(record, 'Success')}>
                                <Button type='link'>Approve</Button>
                            </Popconfirm>
                        }
                        <Button type='link' onClick={() => reviewPost(record)}>Post Review</Button>
                    </Button.Group>
                )
            }
        }
    ];

    useEffect(() => {
        fetchData(pageNum, pageSize, searchParams);
    }, [])

    const fetchData = (pNum, pSize, search) => {
        setLoading(true);
        let params = {
            'page.num': pNum,
            'page.size': pSize,
            ...search,
        };
        fetchAppealList(params).then(res => {
            if (res.page) {
                setTotal(res.page.total);
            }
            if (res.list) {
                setTabData(res.list);
            }
        }).finally(() => setLoading(false))
    }

    const pageChange = (page, size) => {
        setPageNum(page);
        setPageSize(size);
        fetchData(page, size, searchParams);
    }

    const onSearchChange = useDebouncedCallback(
        (tag, value) => {
            let newParams = { ...searchParams };
            newParams[tag] = value;
            setSearchParams(newParams);
            setPageNum(1);
            fetchData(1, pageSize, newParams);
        },
        300
    )

    const reviewPost = (record) => {
        setCurrPost(record.post);
        setOpen(true);
    }

    const riskHandle = (record) => {
        let reason = '';
        let modal = Modal.confirm({
            closable: false,
            title: 'Are you sure to reject this post?',
            content: (
                <div>
                    <Input style={{ color: 'red' }} prefix={<span style={{ color: '#333333' }}>Reason: </span>} placeholder="reject reason" onChange={evt => {
                        reason = evt.target.value;
                        modal.update({
                            okButtonProps: {
                                disabled: !reason.trim(),
                            }
                        })
                    }} allowClear />
                </div>
            ),
            okText: 'Confirm',
            okButtonProps: {
                disabled: !reason.trim(),
            },
            cancelText: 'Cancel',
            onOk: () => approveHandle(record, 'NoPass', reason),
        })
    }

    const approveHandle = (record, status, riskReason) => {
        setLoading(true);
        let params = { id: record.id, status, riskReason };
        updateAppeal(params).then(res => {
            fetchData(pageNum, pageSize, searchParams);
        }).catch(err => {
            message.error(String(err));
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <div className='main-wrapper'>
            <div className='top-part cnt-item'>
                <Flex justify="space-between">
                    <div />
                    {/* <Space wrap style={{ justifyContent: 'flex-end' }}>
                        <Select
                            style={{ width: 160 }}
                            options={ReportTypeOptions}
                            placeholder='Report Type'
                            onChange={value => onSearchChange('reportType', value)}
                            allowClear
                        />
                        <Select
                            style={{ width: 160 }}
                            options={ReportStatusOptions}
                            placeholder='Report Status'
                            onChange={value => onSearchChange('status', value)}
                            allowClear
                        />
                        {
                            searchParams.reportType === 'ReportUser' && <Input
                                prefix={<UserOutlined />}
                                style={{ width: 180 }}
                                placeholder="User search"
                                allowClear
                                onChange={evt => onSearchChange('userKeyword', evt.target.value)}
                            />
                        }
                        {
                            searchParams.reportType === 'ReportPost' && <Input
                                prefix={<FileTextOutlined />}
                                style={{ width: 180 }}
                                placeholder="Post search"
                                allowClear
                                onChange={evt => onSearchChange('postKeyword', evt.target.value)}
                            />
                        }
                        {
                            searchParams.reportType === 'ReportComment' && <Input
                                prefix={<CommentOutlined />}
                                style={{ width: 180 }}
                                placeholder="Comment search"
                                allowClear
                                onChange={evt => onSearchChange('commentKeyword', evt.target.value)}
                            />
                        }
                    </Space> */}
                </Flex>
            </div>
            <div className='cnt-item'>
                <Table loading={loading} rowKey={(record) => record.id} rowClassName={'table-row'} size='small' dataSource={tabData} columns={tabCols} pagination={false} />
            </div>
            <div className='footer-part cnt-item'>
                <Pagination
                    total={total}
                    showTotal={(total) => `total ${total}`}
                    current={pageNum}
                    pageSize={pageSize}
                    showSizeChanger={true}
                    pageSizeOptions={[10, 20, 30, 50]}
                    onChange={(page, size) => pageChange(page, size)}
                />
            </div>

            <PostReview
                from='appeal'
                open={open}
                canEdit={false}
                post={currPost}
                onClose={() => setOpen(false)} />
        </div>
    )
}

export default AppealView;