import { Tabs } from 'antd';
import AppealView from '../../components/report/appeal_view';
import ReportView from '../../components/report/report_view';

export const ReportTypeOptions = [
    { key: 0, label: 'Report User', value: 'ReportUser' },
    { key: 1, label: 'Report Post', value: 'ReportPost' },
    { key: 2, label: 'Report Comment', value: 'ReportComment' },
];

export const ReportStatusOptions = [
    { key: 0, label: 'Need Review', value: 'NoReview' },
    { key: 1, label: 'Pass', value: 'Pass' },
    { key: 2, label: 'No Pass', value: 'NoPass' },
];

export const ReportTypeColorSets = Object.freeze({
    ReportUser: 'magenta',
    ReportPost: 'green',
    ReportComment: 'cyan',
})

const ReportScreen = () => {
    const tabItems = () => {
        return [
            {
                label: 'Report',
                key: '1',
                children: <ReportView />,
            },
            {
                label: 'Appeal',
                key: '2',
                children: <AppealView />
            },
        ]
    }

    return (
        <div className='main-wrapper'>
            <div className='cnt-item'>
                <Tabs tabBarStyle={{padding: '10px 10px 0 10px'}} items={tabItems()} />
            </div>
        </div>
    )
}

export default ReportScreen;