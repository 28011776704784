import React, { useEffect, useState } from 'react';
import { Table, Button, Pagination, message, Popconfirm, Popover } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

import { MainLayoutUrl } from '../../router';
import dayjs from 'dayjs';
import { deleteRole, fetchRoles } from '../../api/admin';
import RolePermissionPreview from '../../components/system/role_permission_preview';

const RolesScreen = () => {
    const navigate = useNavigate();
    const [tabData, setTabData] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const tabCols = [
        {
            title: 'Index',
            dataIndex: 'id',
            key: 'id',
            render: (text, _, index) => ((pageNum - 1) * pageSize + index + 1),
        },
        {
            title: 'Role',
            dataIndex: 'word',
            key: 'word',
        },
        {
            title: 'Desc',
            dataIndex: 'name',
            key: 'name',
            render: (text, _) => {
                if(text) return <span style={{fontSize: 12, color: '#afafaf'}}>{text}</span>;
                return '-';
            }
        },
        {
            title: 'Permission',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => {
               return (
                    <Popover placement='right' trigger={'click'} title={`${record.word} Permission`} content={<RolePermissionPreview actions={text} />}>
                        <Button style={{fontSize: 12, padding: 0}} type='link'>Check Permission</Button>
                    </Popover>
               )
            }
        },
        {
            title: 'Created',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text, _) => {
                return <label style={{ fontSize: 12, color: '#afafaf' }}>{dayjs(text).format('YYYY-MM-DD HH:mm')}</label>
            }
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <Button.Group size='small'>
                        <Button type='link' onClick={() => { editHandle(record) }}>Edit</Button>
                        <Popconfirm
                            title="Delete Action"
                            description="Are you sure to delete this role?"
                            onConfirm={() => { deleteHandle(record) }}
                            okText="Yes"
                            cancelText="No" >
                            <Button danger type='link'>Delete</Button>
                        </Popconfirm>
                    </Button.Group>
                )
            }
        }
    ];

    useEffect(() => {
        setLoading(true);
        fetchData(pageNum, pageSize);
    }, []);

    const fetchData = (pNum, pSize) => {
        let params = { 'page.num': pNum, 'page.size': pSize };
        fetchRoles(params).then(res => {
            if (res.list) {
                setTabData(res.list);
            }
            if (res.page) {
                setTotal(res.page.total);
            }
        }).finally(() => setLoading(false));
    }

    const pageChange = (page, size) => {
        setPageNum(page);
        setPageSize(size);
        fetchData(page, size);
    }

    const createNewRole = () => {
        navigate(MainLayoutUrl.systemconfig.roles.item.path.replace(':id', '0'));
    }

    const deleteHandle = (record) => {
        setLoading(true);
        deleteRole(record.id).then(res => {
            if(res) {
                message.success('deleted!');
                fetchData(pageNum, pageSize);
            }
        }).catch(err => message.error(String(err))).finally(() => setLoading(false));
    }

    const editHandle = (record) => {
        navigate(MainLayoutUrl.systemconfig.roles.item.path.replace(':id', record.id), { state: { detail: JSON.stringify(record) } })
    }

    return (
        <div className='main-wrapper'>
            <div className='top-part cnt-item'>
                <Button type='primary' icon={<PlusOutlined />} onClick={createNewRole}>Create Role</Button>
            </div>
            <div className='cnt-item'>
                <Table loading={loading} rowKey={(record) => record.id} rowClassName={'table-row'} size='small' dataSource={tabData} columns={tabCols} pagination={false} />
            </div>
            <div className='footer-part cnt-item'>
                <Pagination
                    total={total}
                    showTotal={(total) => `total ${total}`}
                    current={pageNum}
                    pageSize={pageSize}
                    showSizeChanger={true}
                    pageSizeOptions={[10, 20, 30, 50]}
                    onChange={(page, size) => pageChange(page, size)}
                />
            </div>
        </div>
    )
}

export default RolesScreen;