import { useEffect, useState } from 'react'
import { fetchVirtualUsers } from '../../api/community';
import { Space, Avatar, Input, Button, Table, Pagination, Flex } from 'antd';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { useDebouncedCallback } from 'use-debounce';
import VirtualUserEditModalView from '../../components/user/virtual_user_edit_modal_view';
import VirtualUsersUploadModalView from '../../components/user/virtual_user_upload_modal_view';

const VirtualUserScreen = () => {
    const [tabData, setTabData] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [keyword, setKeyword] = useState();
    const [uploadModalOpen, setUploadModalOpen] = useState(false);
    const [currUser, setCurrUser] = useState();

    const tabCols = [
        {
            title: 'Index',
            dataIndex: 'id',
            key: 'id',
            render: (text, _, index) => ((pageNum - 1) * pageSize + index + 1),
        },
        {
            title: 'Avatar',
            dataIndex: 'picture',
            key: 'picture',
            render: (text, _) => {
                return <Avatar src={text} size={36} />
            }
        },
        {
            title: 'User ID',
            dataIndex: 'userId',
            key: 'userId',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Username',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <Button.Group size='small'>
                        <Button type='link' onClick={() => { editHandle(record) }}>Edit</Button>
                        {/* <Popconfirm
                            title="Delete Action"
                            description="Are you sure to delete this virtual user?"
                            onConfirm={() => { deleteHandle(record) }}
                            okText="Yes"
                            cancelText="No" >
                            <Button danger type='link'>Delete</Button>
                        </Popconfirm> */}
                    </Button.Group>
                )
            }
        }
    ];

    useEffect(() => {
        setLoading(true);
        getVirtualUsers(pageNum, pageSize, keyword);
    }, [])

    const getVirtualUsers = useDebouncedCallback(
        (pNum, pSize, search) => {
            let params = {
                'page.num': pNum,
                'page.size': pSize,
                'keyword': search
            }
            fetchVirtualUsers(params).then(res => {
                if (res.list) {
                    setTabData(res.list);
                }
                if (res.page) {
                    setTotal(res.page.total);
                }
            }).finally(() => setLoading(false))
        },
        300)

    const pageChange = (page, size) => {
        setPageNum(page);
        setPageSize(size);
        getVirtualUsers(page, size, keyword);
    }

    const addVirtualUser = () => {
        setCurrUser(null);
        setOpen(true);
    }

    const editHandle = (record) => {
        setCurrUser(record);
        setOpen(true);
    }

    // const deleteHandle = (record) => {
    //     setLoading(true);
    //     deleteRecommendUser(record.id).then(res => {
    //         if (res) {
    //             message.success('deleted!');
    //             getVirtualUsers(pageNum, pageSize, keyword);
    //         }
    //     }).catch(err => {
    //         message.error(String(err));
    //     }).finally(() => {
    //         setLoading(false);
    //     })
    // }

    const onKeywordChange = (value) => {
        setKeyword(value);
        setPageNum(1);
        getVirtualUsers(1, pageSize, value);
    }

    return (
        <div className='main-wrapper'>
            <div className='top-part cnt-item'>

                <Flex justify='space-between'>
                    <Space>
                        <Button type='primary' icon={<PlusOutlined />} onClick={addVirtualUser}>Add Virutal User</Button>
                        <Button type='primary' onClick={() => setUploadModalOpen(true)} icon={<UploadOutlined />}>Upload Virtual Users</Button>
                    </Space>
                    <Input
                        style={{width: 240}}
                        placeholder="keywords search"
                        allowClear
                        value={keyword}
                        onChange={evt => onKeywordChange(evt.target.value)}
                    />
                </Flex>
            </div>
            <div className='cnt-item'>
                <Table loading={loading} rowKey={(record) => record.userId} rowClassName={'table-row'} size='small' dataSource={tabData} columns={tabCols} pagination={false} />
            </div>
            <div className='footer-part cnt-item'>
                <Pagination
                    total={total}
                    showTotal={(total) => `total ${total}`}
                    current={pageNum}
                    pageSize={pageSize}
                    showSizeChanger={true}
                    pageSizeOptions={[10, 20, 30, 50]}
                    onChange={(page, size) => pageChange(page, size)}
                />
            </div>
            <VirtualUserEditModalView
                open={open}
                defaultUser={currUser}
                onClose={() => setOpen(false)}
                onUpdate={() => {
                    getVirtualUsers(pageNum, pageSize, keyword);
                }} />

            <VirtualUsersUploadModalView
                open={uploadModalOpen}
                onClose={() => {
                    setUploadModalOpen(false);
                }}
                onUpdate={() => {
                    setPageNum(1);
                    setKeyword(null);
                    getVirtualUsers(1, pageSize, null);
                }} />
        </div>
    )
}


export default VirtualUserScreen;