import React, { useEffect, useState } from 'react';
import { Table, Button, Pagination, Space, message, Popconfirm, Tag } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { deleteAdmin, fetchAdmins } from '../../api/admin';
import AdminUserEditModalView from '../../components/system/admin_edit_modal_view';

const AdminsScreen = () => {
    const [tabData, setTabData] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [currAdmin, setCurrAdmin] = useState();
    const [modalOpen, setModalOpen] = useState(false)
    const tabCols = [
        {
            title: 'Index',
            dataIndex: 'id',
            key: 'id',
            render: (text, _, index) => ((pageNum - 1) * pageSize + index + 1),
        },
        {
            title: 'Admin Name',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
            render: (_, record) => {
                return record.role.word ? record.role.word : '-'
            }
        },
        {
            title: 'Desc',
            dataIndex: 'remark',
            key: 'remark',
            render: (text, _) => {
                if(text) {
                    return <div style={{fontSize: 12, color: '#afafaf'}}>{text}</div>
                }
                return '-'
            }
        },
        {
            title: 'Created',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text, _) => {
                return <label style={{ fontSize: 12, color: '#afafaf' }}>{dayjs(text).format('YYYY-MM-DD HH:mm')}</label>
            }
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <Button.Group size='small'>
                        <Button type='link' onClick={() => { editHandle(record) }}>Edit</Button>
                        <Popconfirm
                            title="Delete Action"
                            description="Are you sure to withdraw this admin account?"
                            onConfirm={() => { deleteHandle(record) }}
                            okText="Yes"
                            cancelText="No" >
                            <Button danger type='link'>Delete</Button>
                        </Popconfirm>
                    </Button.Group>
                )
            }
        }
    ];

    useEffect(() => {
        setLoading(true);
        fetchData(pageNum, pageSize);
    }, []);

    const fetchData = (pNum, pSize) => {
        let params = { 'page.num': pNum, 'page.size': pSize };
        fetchAdmins(params).then(res => {
            if (res.list) {
                setTabData(res.list);
            }
            if (res.page) {
                setTotal(res.page.total);
            }
        }).finally(() => setLoading(false));
    }

    const pageChange = (page, size) => {
        setPageNum(page);
        setPageSize(size);
        fetchData(page, size);
    }

    const createNewAdmin = () => {
       setCurrAdmin(null);
       setModalOpen(true);
    }

    const deleteHandle = (record) => {
        setLoading(true);
        deleteAdmin(record.id).then(res => {
            if(res) {
                message.success('deleted!');
                fetchData(pageNum, pageSize);
            }
        }).catch(err => message.error(String(err))).finally(() => setLoading(false));
    }

    const editHandle = (record) => {
        setCurrAdmin(record);
        setModalOpen(true);
    }

    return (
        <div className='main-wrapper'>
            <div className='top-part cnt-item'>
                <Button type='primary' icon={<PlusOutlined />} onClick={createNewAdmin}>Create Admin</Button>
            </div>
            <div className='cnt-item'>
                <Table loading={loading} rowKey={(record) => record.id} rowClassName={'table-row'} size='small' dataSource={tabData} columns={tabCols} pagination={false} />
            </div>
            <div className='footer-part cnt-item'>
                <Pagination
                    total={total}
                    showTotal={(total) => `total ${total}`}
                    current={pageNum}
                    pageSize={pageSize}
                    showSizeChanger={true}
                    pageSizeOptions={[10, 20, 30, 50]}
                    onChange={(page, size) => pageChange(page, size)}
                />
            </div>

            <AdminUserEditModalView
                open={modalOpen}
                defaultAdmin={currAdmin}
                onClose={() => setModalOpen(false)}
                onUpdate={() => fetchData(pageNum, pageSize)}
             />
        </div>
    )
}
export default AdminsScreen;