import React, { useEffect, useRef, useState } from 'react';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Space, Input, Tag, theme, Tooltip, Popconfirm } from 'antd';
const tagInputStyle = {
    width: 160,
    height: 22,
    marginInlineEnd: 8,
    verticalAlign: 'top',
};
const ContentTagItemEditView = ({ defaultTags, onUpdate, onDelete }) => {
    const { token } = theme.useToken();
    const [tags, setTags] = useState([]);
    const [inputVisible, setInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [editInputIndex, setEditInputIndex] = useState(-1);
    const [editInputValue, setEditInputValue] = useState('');
    const inputRef = useRef(null);
    const editInputRef = useRef(null);

    useEffect(() => {
        if (defaultTags && Array.from(defaultTags).length > 0) {
            setTags(defaultTags);
        } else {
            setTags([])
        }
    }, [defaultTags]);

    useEffect(() => {
        if (inputVisible) {
            inputRef.current?.focus();
        }
    }, [inputVisible]);

    useEffect(() => {
        editInputRef.current?.focus();
    }, [editInputValue]);

    const handleClose = (removedTag) => {
        if(removedTag.id && parseInt(removedTag.id) > 0) {
            onDelete(removedTag.id);
        } else {
            const newTags = tags.filter((tag) => tag.name !== removedTag.name);
            setTags(newTags);
            onUpdate(newTags);
        }
    };

    const showInput = () => {
        setInputVisible(true);
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleInputConfirm = () => {
        if (inputValue && !tags.map(item => item.name).includes(inputValue)) {
            let newData = [...tags, { name: inputValue}]
            setTags(newData);
            onUpdate(newData);
        }
        setInputVisible(false);
        setInputValue('');
    };

    const handleEditInputChange = (e) => {
        setEditInputValue(e.target.value);
    };

    const handleEditInputConfirm = () => {
        const newTags = [...tags];
        newTags[editInputIndex].name = editInputValue;
        setTags(newTags);
        setEditInputIndex(-1);
        setEditInputValue('');
        onUpdate(newTags);
    };
    const tagPlusStyle = {
        height: 22,
        background: token.colorBgContainer,
        borderStyle: 'dashed',
    };
    // console.log('currTags', tags);
    return (
        <Space wrap>
            {tags.map((item, index) => {
                let tag = item.name ?? '';
                if (editInputIndex === index) {
                    return (
                        <Input
                            ref={editInputRef}
                            key={index}
                            size="small"
                            style={tagInputStyle}
                            value={editInputValue}
                            onChange={handleEditInputChange}
                            onBlur={handleEditInputConfirm}
                            onPressEnter={handleEditInputConfirm}
                        />
                    );
                }
                const isLongTag = tag.length > 20;
                const tagElem = (
                    <Tag
                        color='green'
                        key={index}
                        closable
                        style={{
                            userSelect: 'none',
                            display: 'block !important',
                        }}
                        closeIcon={
                            item.id && parseInt(item.id) > 0 ?
                            <Popconfirm title="delete item, confirm?" onConfirm={() => handleClose(item)}>
                                <CloseOutlined onClick={(evt) => {
                                    evt.preventDefault();
                                    evt.stopPropagation();
                                }} />
                            </Popconfirm>
                            :
                            <CloseOutlined onClick={(evt) => {
                                evt.preventDefault();
                                evt.stopPropagation();
                                handleClose(item)
                            }} />
                        }
                    >
                        <span
                            onDoubleClick={(e) => {
                                setEditInputIndex(index);
                                setEditInputValue(tag);
                                e.preventDefault();
                            }}
                        >
                            {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                        </span>
                    </Tag>
                );
                return isLongTag ? (
                    <Tooltip title={tag} key={tag}>
                        {tagElem}
                    </Tooltip>
                ) : (
                    tagElem
                );
            })}
            {inputVisible ? (
                <Input
                    ref={inputRef}
                    type="text"
                    size="small"
                    style={tagInputStyle}
                    value={inputValue}
                    onChange={handleInputChange}
                    onBlur={handleInputConfirm}
                    onPressEnter={handleInputConfirm}
                />
            ) : (
                <Tag style={tagPlusStyle} icon={<PlusOutlined />} onClick={showInput}>
                    New Second Level
                </Tag>
            )}
        </Space>
    );
};
export default ContentTagItemEditView;