import { CloseOutlined } from "@ant-design/icons";
import { Form, Input, Space, Drawer, Button, message } from "antd";
import { useEffect, useState } from "react";
import { deleteCourseQuestionOption, deleteDailyQuestionOption, updateCourseQuestions, updateDailyQuestions } from "../../api/course";

const QuestionEditView = ({ defaultQuestion, open, courseId, onClose, onUpdate, isDaily = false }) => {
    const letterSets = 'ABCDEDFGHIJKLMNOPQRSTUVWXYZ';
    const [loading, setLoading] = useState(false);
    const [question, setQuestion] = useState({
        courseId,
        title: '',
        answer: '',
        analysis: '',
        options: [{
            name: '',
            val: '',
        }]
    });
    const [questionForm] = Form.useForm();

    useEffect(() => {
        if (defaultQuestion) {
            questionForm.setFieldValue('title', defaultQuestion.title);
            questionForm.setFieldValue('options', defaultQuestion.options.map(item => ({ ...item, key: item.id })));
            questionForm.setFieldValue('answer', defaultQuestion.answer);
            questionForm.setFieldValue('analysis', defaultQuestion.analysis);
        } else {
            questionForm.setFieldValue('title', null);
            questionForm.setFieldValue('options', null);
            questionForm.setFieldValue('answer', null);
            questionForm.setFieldValue('analysis', null);
        }
        setQuestion(defaultQuestion);
    }, [defaultQuestion]);

    useEffect(() => {
        if (!open) {
            questionForm.setFieldValue('title', null);
            questionForm.setFieldValue('options', null);
            questionForm.setFieldValue('answer', null);
            questionForm.setFieldValue('analysis', null);
        }
    }, [open])

    const onSubmit = () => {
        let title = questionForm.getFieldValue('title');
        let options = questionForm.getFieldValue('options');
        let answer = questionForm.getFieldValue('answer');
        let analysis = questionForm.getFieldValue('analysis');
        if (!title) {
            message.warning('topic can not be empty!');
            return;
        } else if (!options || options.some(item => !item || !item.name || !String(item.name).trim() || !item.val || !String(item.val).trim())) {
            message.warning('option item can not be empty!');
            return;
        } else if (!answer) {
            message.warning('answer can not be empty!');
            return;
        }
        setLoading(true);
        let params = {
            ...question, title, answer, analysis, options,
        }
        if (isDaily) {
            updateDailyQuestions({ list: [params] }).then(res => {
                if(res) {
                    message.success('updated!');
                    onClose();
                    onUpdate();
                }
            }).catch((err) => {
                message.error(String(err));
            }).finally(() => {
                setLoading(false);
            });
        } else {
            updateCourseQuestions({ list: [params] }).then(res => {
                if(res) {
                    message.success('updated!');
                    onClose();
                    onUpdate();
                }
            }).catch((err) => {
                message.error(String(err));
            }).finally(() => {
                setLoading(false);
            });
        }
    }

    const getOptionPrefix = (index) => {
        return letterSets[index];
    }

    return (
        <Drawer
            title={defaultQuestion ? `Edit${isDaily ? ' Daily' : ''} Question` : `Create${isDaily ? ' Daily' : ''} Question`}
            width={480}
            open={open}
            onClose={onClose}
            maskClosable
            styles={{
                body: {
                    paddingBottom: 80,
                },
            }}
            extra={
                <Space>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button onClick={onSubmit} type="primary" loading={loading}>
                        Submit
                    </Button>
                </Space>
            }
        >
            <Form form={questionForm} layout="vertical">
                <Form.Item label="Question Topic" name='title'>
                    <Input.TextArea placeholder="question topic" autoSize allowClear />
                </Form.Item>
                <Form.Item label="Options">
                    <Form.List name='options'>
                        {(subFields, subOpt) => {
                            return (
                                <div style={{ display: 'flex', flexDirection: 'column', rowGap: 16 }}>
                                    {subFields.map((subField, index) => (
                                        <div key={subField.key} style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                            <div>
                                                <Form.Item noStyle name={[subField.name, 'name']}>
                                                    <Input style={{ width: 80 }} allowClear placeholder={`eg: ${getOptionPrefix(index)}`} />
                                                </Form.Item>
                                            </div>
                                            <div style={{ flex: 1, marginLeft: 10 }}>
                                                <Form.Item noStyle name={[subField.name, 'val']}>
                                                    <Input.TextArea placeholder="answer option" allowClear autoSize />
                                                </Form.Item>
                                            </div>
                                            <CloseOutlined
                                                style={{ marginLeft: 20 }}
                                                onClick={async () => {
                                                    let optionId = (question.options && question.options[subField.name]) ? question.options[subField.name].id : null;
                                                    if(optionId) {
                                                        setLoading(true);
                                                        if(isDaily) {
                                                            await deleteDailyQuestionOption(optionId)
                                                        } else {
                                                            await deleteCourseQuestionOption(optionId);
                                                        }
                                                        setLoading(false);
                                                        onUpdate();
                                                    }
                                                    subOpt.remove(subField.name);
                                                }}
                                            />
                                        </div>
                                    ))}
                                    <Button loading={loading} type="dashed" onClick={() => subOpt.add()} block>
                                        + Add New Option
                                    </Button>
                                </div>
                            );
                        }}
                    </Form.List>
                </Form.Item>
                <Form.Item label="Right Answer" name='answer'>
                    <Input placeholder="ex: A or A,B" allowClear />
                </Form.Item>
                <Form.Item label="Analysis" name='analysis'>
                    <Input.TextArea placeholder="answer analysis" autoSize allowClear />
                </Form.Item>
            </Form>
        </Drawer>
    )
}

export default QuestionEditView;