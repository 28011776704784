import {useState} from 'react';

const UgcForm = ({ defaultData, onUpdate }) => {
    const [data, setData] = useState(defaultData ?? {});

    const onFormChange = (value, flag) => {
        let updated = { ...data };
        updated[flag] = value;
        setData(updated);
        if (onUpdate) onUpdate(updated);
    }
    
    return (
        <div>

        </div>
    )
}

export default UgcForm;