import request from "../utils/request";

export function fetchAdminInfo() {
    return request.get('/info');
}

export function fetchRoles(params) {
    return request.get('/role/list', params);
}

export function createRole(params) {
    return request.post('/role/create', params);
}

export function updateRole(params) {
    return request.patch('/role/update', params);
}

export function deleteRole(ids) {
    return request.dataDel(`/role/delete?ids=${ids}`)
}

export function fetchAdmins(params) {
    return request.get('/user/list', params);
}

export function createAdmin(params) {
    return request.post('/user/create', params);
}

export function updateAdmin(params) {
    return request.patch('/user/update', params);
}

export function deleteAdmin(ids) {
    return request.dataDel(`/user/delete?ids=${ids}`)
}

export function fetchAdminActions(params) {
    return request.get('/action/list', params);
}

export function createAdminAction(params) {
    return request.post('/action/create', params);
}

export function updateAdminAction(params) {
    return request.patch('/action/update', params);
}

export function deleteAdminAction(ids) {
    return request.dataDel(`/action/delete?ids=${ids}`)
}