import { Typography } from "antd";
import { Col, Row, Statistic, Card } from "antd";
import dayjs from "dayjs";
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';

const StatisticView = () => {
    return (
        <Row gutter={16} wrap>
            <Col span={8}>
                <Card styles={{ body: { padding: 20 } }}>
                    <Statistic
                        title={<Typography.Text className="static-title">Bounty In Progress</Typography.Text>}
                        value={5000}
                        groupSeparator={','}
                        formatter={(value) => {
                            return (
                                <div>
                                    <Typography.Text className="static-value">${value}</Typography.Text>
                                    <div className="static-tips">Total rewards in <b>6</b> events</div>
                                </div>
                            )
                        }}
                    />
                </Card>
            </Col>
            <Col span={8}>
                <Card styles={{ body: { padding: 20 } }}>
                    <Statistic
                        title={<Typography.Text className="static-title">Daily Pending Posts</Typography.Text>}
                        value={4}
                        groupSeparator={','}
                        formatter={(value) => {
                            return (
                                <div>
                                    <Typography.Text className="static-value">
                                        {value}
                                        <label style={{ color: '#04d182' }}>-9.27% <ArrowDownOutlined /></label>
                                    </Typography.Text>
                                    <div className="static-tips">Compare to previous day ({dayjs(Date.now()).add(-1, 'day').format('MM/DD')})</div>
                                </div>
                            )
                        }}
                    />
                </Card>
            </Col>
            <Col span={8}>
                <Card styles={{ body: { padding: 20 } }}>
                    <Statistic
                        title={<Typography.Text className="static-title">Daily Risk Posts</Typography.Text>}
                        value={7}
                        groupSeparator={','}
                        formatter={(value) => {
                            return (
                                <div>
                                    <Typography.Text className="static-value">
                                        {value}
                                        <label style={{ color: '#ff6b72' }}>+4.28% <ArrowUpOutlined /></label>
                                    </Typography.Text>
                                    <div className="static-tips">Compare to previous day ({dayjs(Date.now()).add(-1, 'day').format('MM/DD')})</div>
                                </div>
                            )
                        }}
                    />
                </Card>
            </Col>
        </Row>
    )
}

export default StatisticView;